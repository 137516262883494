import TzContract from "Stores/Contract/TzContract";
import StoreWorkflow from "Stores/StoreWorkflow";
import UserStore from "Stores/UserStore";

/**
 * Service that will automatically instanciate other services on load
 */
export default class AutoLoadServices {
	private static instance: AutoLoadServices;

	private constructor() {
		AutoLoadServices.instance = this;
		this.init();
	}

	public static load(): void {
		this.instance ?? new this();
	}

	private init() {
		StoreWorkflow.getInstance();
		UserStore.getInstance();
		TzContract.getInstance();
	}
}
