import { Component, ReactNode } from "react";
import classNames from "classnames";

// Components
import { EMintModalType } from "Components/Materials/MintModal";
import I18n from "Components/Elements/I18n";

// Configs
import Config from "Configs/Config";

// Assets
import { ReactComponent as TezosIcon } from "assets/images/icons/tezos.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	title: ReactNode;
	descriptionPartOne: ReactNode;
	descriptionPartTwo: ReactNode;
	onMintButtonClick: (mintModalType: EMintModalType) => void;
	mintType: EMintModalType;
};
type IState = {};

export default class SeasonSet extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onMintButtonClick = this.onMintButtonClick.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<p className={classes["set-title"]}>{this.props.title}</p>

				<div className={classes["set-infos"]}>
					<p className={classes["set-description"]}>
						<span>{this.props.descriptionPartOne}</span>
						<span>{this.props.descriptionPartTwo}</span>
					</p>

					{this.isSetAvailable() ? (
						<button className={classes["button"]} onClick={this.onMintButtonClick}>
							<span>
								<I18n map="general_text.mint" />
							</span>
							&nbsp;
							<span>
								{Config.getInstance().get().setsPrices.seasonSet} <TezosIcon className={classes["tezos-icon"]} />
							</span>
						</button>
					) : (
						<button className={classNames(classes["button"], classes["center"])}>
							<span>
								<I18n map="general_text.available_soon" />
							</span>
						</button>
					)}
				</div>
			</div>
		);
	}

	private onMintButtonClick() {
		this.props.onMintButtonClick(this.props.mintType);
	}

	private isSetAvailable() {
		const date = this.getCommingSoonDate();
		const now = new Date();

		if (!date) return false;
		return date.getTime() < now.getTime();
	}

	private getCommingSoonDate() {
		switch (this.props.mintType) {
			case EMintModalType.SEASON_SET_1:
				return new Date(Config.getInstance().get().setsComingSoon.seasonSet.partOne.end);
			case EMintModalType.SEASON_SET_2:
				return new Date(Config.getInstance().get().setsComingSoon.seasonSet.partTwo.end);
			case EMintModalType.SEASON_SET_3:
				return new Date(Config.getInstance().get().setsComingSoon.seasonSet.partThree.end);
			case EMintModalType.SEASON_SET_4:
				return new Date(Config.getInstance().get().setsComingSoon.seasonSet.partFour.end);

			default:
				return null;
		}
	}
}
