import { Component } from "react";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import ThanksForPurchaseModal from "Components/Materials/ThanksForPurchaseModal";
import Loader from "Components/Elements/Loader";

// Entities
import { ICalandarNft } from "Entities/CalandarNft";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import { IAppAssetOwner } from "Entities/CollectionAsset/appAssetOwner";
import { CollectionAssetStatus } from "Entities/CollectionAsset/enums/collectionAssetStatus";

// Configs
import Config from "Configs/Config";

// Stores
import Wallet from "Stores/Wallet";
import Toasts from "Stores/Toasts";
import TzContract from "Stores/Contract/TzContract";

// Api
import Collection from "Api/Factory/AppCollection/Collection";

// Assets
import { ReactComponent as TezosIcon } from "assets/images/icons/tezos.svg";
import { ReactComponent as SuccessIcon } from "assets/images/icons/check.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	nftInfos: ICalandarNft;
	asset: IAsset;
	userAddress: string | null;
};
type IState = {
	nbOfAssetPossessed: number;
	isThanksForPurchaseModalOpen: boolean;
	isLoading: boolean;
};

export default class GalleryFooter extends Component<IProps, IState> {
	private readonly _quantityToMint = 1;

	constructor(props: IProps) {
		super(props);
		this.state = {
			isThanksForPurchaseModalOpen: false,
			nbOfAssetPossessed: 0,
			isLoading: false,
		};
		this.onMintButtonClick = this.onMintButtonClick.bind(this);
		this.onThanksForPurchaseModalClose = this.onThanksForPurchaseModalClose.bind(this);
		this.setAmountMintedForAppAssetOwner = this.setAmountMintedForAppAssetOwner.bind(this);
	}

	override render() {
		const redirectUrl = `${Config.getInstance().get().support.minteedUrl}collection/${Config.getInstance().get().collectionId}/asset/${
			this.props.asset.id
		}`;

		return (
			<div className={classes["root-container"]}>
				{this.renderAmountMintedByUser()}
				<div className={classes["root"]}>
					<div className={classes["nft-infos"]}>
						<p className={classes["nft-month"]}>
							<I18n map={`nfts.${this.props.nftInfos.month}.month`} />
						</p>
					</div>

					{(this.props.asset?.tokenId! === 0 || Number.isInteger(this.props.asset?.tokenId!)) &&
					this.props.asset?.status === CollectionAssetStatus.ONCHAIN ? (
						this.isTotalSupplyReached() ? (
							<a
								href={Config.getInstance().get().support.objktCollectionUrl}
								className={classes["button"]}
								target="_blank"
								rel="noreferrer">
								<I18n map="general_text.buy_on_secondary_market" />
							</a>
						) : (
							<button className={classes["button"]} onClick={this.onMintButtonClick} disabled={this.state.isLoading}>
								{this.state.isLoading ? (
									<>
										<Loader className={classes["loader"]} />
										<span>
											<I18n map="general_text.purchase_in_progress" />
										</span>
									</>
								) : (
									<>
										<span>
											<I18n map="general_text.mint" />
										</span>
										&nbsp;
										<span>
											{Config.getInstance().get().singleNftPrice} <TezosIcon className={classes["tezos-icon"]} />
										</span>
									</>
								)}
							</button>
						)
					) : (
						<button className={classNames(classes["button"], classes["center"])}>
							<I18n map="general_text.not_available" />
						</button>
					)}

					<ThanksForPurchaseModal
						redirectUrl={redirectUrl}
						isOpen={this.state.isThanksForPurchaseModalOpen}
						onCloseModal={this.onThanksForPurchaseModalClose}
					/>
				</div>
			</div>
		);
	}

	override componentDidMount() {
		this.setAmountMintedForAppAssetOwner();
	}

	override componentDidUpdate(prevProps: Readonly<IProps>) {
		if (this.props.asset !== prevProps.asset || this.props.userAddress !== prevProps.userAddress)
			this.setAmountMintedForAppAssetOwner();
	}

	private isTotalSupplyReached() {
		return this.props.asset.amountAvailable === 0;
	}

	private setAmountMintedForAppAssetOwner() {
		const nbOfAssetPossessed = this.getAmountMintedForAppAssetOwner();
		this.setState({ nbOfAssetPossessed: nbOfAssetPossessed });
	}

	private onThanksForPurchaseModalClose() {
		this.setState({ isThanksForPurchaseModalOpen: false });
	}

	private onMintButtonClick() {
		this.mintAsset(this.props.asset.tokenId!, this._quantityToMint, this.props.asset.mintPrice);
	}

	private async mintAsset(assetContractKeys: number, assetQuantities: number, assetPrice: string) {
		const titleSuccess = (
			<div className={classes["title-toast"]}>
				<SuccessIcon />
				{I18n.translate("toasts.mint_success")}
			</div>
		);

		try {
			if (!Wallet.getInstance().getWalletData().userAddress) await Wallet.getInstance().connect();
			this.setState({ isLoading: true });

			if (this.getMaxMintable() < assetQuantities) throw new Error("Max mintable number can not be exceeded.");

			const collection = await Collection.getInstance().get({
				collectionId: Config.getInstance().get().collectionId,
			});
			const collectionContract = await TzContract.getInstance().collectionContract(collection?.contractAddress!);
			const transaction = await collectionContract.mintAsset(assetContractKeys, assetQuantities, assetPrice);
			await TzContract.getInstance().confirmOperation(transaction.opHash);

			Toasts.getInstance().open({
				text: I18n.translate("toasts.mint_success"),
				title: titleSuccess,
				time: 5000,
				closable: true,
			});

			this.setState({ isThanksForPurchaseModalOpen: true, nbOfAssetPossessed: this.state.nbOfAssetPossessed + 1, isLoading: false });
		} catch (e) {
			console.log(e);
			const error = e as Error;
			Toasts.getInstance().open({
				text: error.message,
				title: I18n.translate("toasts.an_error_occured"),
				time: 5000,
				closable: true,
			});
			this.setState({ isLoading: false });
		}
	}

	private getMaxMintable(): number {
		const asset = this.props.asset;
		const amountMintedForCurrentUser = this.getAmountMintedForAppAssetOwner();

		if (asset && asset.appAssetOwners) {
			const amountAvailable = asset?.amountAvailable;

			const maxMintPerWallet = asset.maxMintPerWallet ?? 1;
			const currentMaxMintPerWallet = maxMintPerWallet - amountMintedForCurrentUser;
			return Math.min(amountAvailable, currentMaxMintPerWallet);
		}
		return 0;
	}

	private getAmountMintedForAppAssetOwner(): number {
		const appAssetOwner = this.getAppAssetOwner();
		if (!appAssetOwner) return 0;
		return appAssetOwner.balance;
	}

	private getAppAssetOwner(): IAppAssetOwner | undefined {
		return this.props.asset.appAssetOwners.find((owner) => owner.appUser.userAddress === this.props.userAddress);
	}

	private renderAmountMintedByUser() {
		if (!this.props.userAddress) return;

		const amountMinted = this.state.nbOfAssetPossessed;
		const config = Config.getInstance().get();

		return (
			<div className={classes["nb-asset-minted"]}>
				<p>
					<I18n map="pages.gallery.nb_asset_minted" vars={{ nbAssetMinted: amountMinted.toString() }} />
				</p>
				{amountMinted > 0 && (
					<a
						className={classes["link"]}
						href={`${config.support.minteedUrl}collection/${config.collectionId}/asset/${this.props.asset.id}`}
						target="_blank"
						rel="noreferrer">
						<I18n map="pages.gallery.link_to_asset" />
					</a>
				)}
			</div>
		);
	}
}
