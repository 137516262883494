import { Component, ReactNode } from "react";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	hasCloseButton?: boolean;
	onClose?: () => void;
	className?: string;
	children?: ReactNode;
};
type IState = {};

export default class Modal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.closePopup = this.closePopup.bind(this);
	}

	override render() {
		if (!this.props.isOpen) return null;

		return (
			<div className={classes["root"]}>
				<div className={classes["background"]} onClick={this.closePopup} />
				<div className={classes["container"]}>{this.props.children}</div>
			</div>
		);
	}

	private closePopup() {
		this.props.onClose?.();
	}
}
