import EventService from "Services/EventEmitter";

export default class PopupStore {
	private static ctx: PopupStore;
	private readonly event = new EventService();
	private _isEligibleForRewards: boolean | null = null;
	private _isEligibleForFreeNft: boolean = false;

	public static getInstance() {
		if (!PopupStore.ctx) {
			PopupStore.ctx = new PopupStore();
		}

		return PopupStore.ctx;
	}

	public set isEligibleForRewards(value: boolean | null) {
		this._isEligibleForRewards = value;
		this.event.emit("change", value);
	}

	public get isEligibleForRewards() {
		return this._isEligibleForRewards;
	}

	public set isEligibleForFreeNft(value: boolean) {
		this._isEligibleForFreeNft = value;
	}

	public get isEligibleForFreeNft() {
		return this._isEligibleForFreeNft;
	}

	public onChange(callback: (isOpen: boolean) => void) {
		this.event.on("change", callback);
		return () => {
			this.event.off(" change", callback);
		};
	}
}
