import React, { useEffect } from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";

// Components
import ToastHandler from "./Materials/ToastsHandler";
import Module from "./Elements/Module";

// Stores
import ThemeMode from "../Stores/ThemeMode";

// Pages
import Home from "./Pages/Home";
import Gallery from "./Pages/Gallery";
import CreateWalletTuto from "./Pages/CreateWalletTuto";

type IProps = {};
type IState = {};

declare global {
	interface Window {
		intercomSettings: any;
	}
}

export default class Main extends React.Component<IProps, IState> {
	private removeThemeModeOnSwitch = () => {};

	public constructor(props: IProps) {
		super(props);
		this.state = {};
		this.updateThemeMode();
	}

	public override render(): JSX.Element {
		const pageConfig = Module.config.pages;
		return (
			<Router basename="/">
				<ToastHandler />

				<Routes>
					<Route element={<BindRouter />}>
						<Route
							element={
								<Module from={pageConfig.Home}>
									<Home />
								</Module>
							}
							path={pageConfig.Home.props.path}
						/>

						<Route
							element={
								<Module from={pageConfig.Gallery}>
									<Gallery />
								</Module>
							}
							path={pageConfig.Gallery.props.path}
						/>

						<Route
							element={
								<Module from={pageConfig.TutoCreateWallet}>
									<CreateWalletTuto />
								</Module>
							}
							path={pageConfig.TutoCreateWallet.props.path}
						/>

						<Route element={<Navigate to={pageConfig.Home.props.path} replace />} path="*" />
					</Route>
				</Routes>
			</Router>
		);
	}

	public override componentDidMount() {
		this.removeThemeModeOnSwitch = ThemeMode.getInstance().onSwitch(() => this.updateThemeMode());
		Module.config.GoogleTagManager.enabled && this.launchGoogleTagManager();
		Module.config.Intercom.enabled && this.launchIntercom();
	}

	public override componentWillUnmount() {
		this.removeThemeModeOnSwitch();
	}

	private updateThemeMode() {
		document.body.setAttribute("theme-mode", ThemeMode.getInstance().type);
	}

	private launchGoogleTagManager() {
		const tagManagerArgs = {
			gtmId: Module.config.GoogleTagManager.props.containerId,
		};

		TagManager.initialize(tagManagerArgs);
	}

	private launchIntercom() {
		const APP_ID = Module.config.Intercom.props.appId;
		window.intercomSettings = {
			app_id: APP_ID,
		};

		(function () {
			var w = window as any;
			var ic = w.Intercom;
			if (typeof ic === "function") {
				ic("reattach_activator");
				ic("update", w.intercomSettings);
			} else {
				var d = document;
				var i: any = function () {
					i.c(arguments);
				};
				i.q = [];
				i.c = function (args: any) {
					i.q.push(args);
				};
				w.Intercom = i;
				var l = function () {
					var s = d.createElement("script");
					s.type = "text/javascript";
					s.async = true;
					s.src = "https://widget.intercom.io/widget/" + APP_ID;
					var x = d.getElementsByTagName("script")[0];
					x?.parentNode?.insertBefore(s, x);
				};
				if (w.attachEvent) {
					w.attachEvent("onload", l);
				} else {
					w.addEventListener("load", l, false);
				}
			}
		})();
	}
}

function BindRouter() {
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.setAttribute("route", location.pathname);
	}, [location.pathname]);

	return <Outlet />;
}
