import { IUser } from "Entities/AuthFactory/user";
import jwt, { JwtPayload } from "jsonwebtoken";
import TzContract from "Stores/Contract/TzContract";
import JwtStore, { IJwtToken } from "Stores/JwtStore";
import UserStore from "Stores/UserStore";
import Wallet from "Stores/Wallet";
import { IWalletData } from "Services/Wallet/IWalletInterface";
import Config from "Configs/Config";
import PopupStore from "./PopupStore";

export default class StoreWorkflow {
	private static instance: StoreWorkflow;

	private constructor() {
		StoreWorkflow.instance = this;
		this.initEvents();
	}

	public static getInstance() {
		return this.instance ?? new this();
	}

	private initEvents() {
		this.onWalletChange();
		this.onUserChange();
		this.onJwtTokenChange();
	}

	private onWalletChange() {
		Wallet.getInstance().onChange(async (walletData: IWalletData) => {
			const token = JwtStore.getInstance().accessToken;
			if (!token) {
				return JwtStore.getInstance().presign();
			}
			const decodedToken = jwt.decode(token) as JwtPayload;
			const tokenUserAddress = decodedToken["userAddress"];

			if (walletData.userAddress) {
				await this.onRefreshWallet(walletData, tokenUserAddress);
				await this.onChangeAccount(walletData, tokenUserAddress);
			}

			this.onSignInOrSignOutWallet(walletData, tokenUserAddress);

			TzContract.getInstance().setContractData(walletData.provider);
		});
	}

	private onUserChange() {
		UserStore.getInstance().onChange(async (user) => {
			this.refreshToken(user);
			this.checkForEligibility(user);
		});
	}

	private checkForEligibility(user: IUser | null) {
		const eligibleForRewards = Config.getInstance().get().eligibleForRewards;
		const eligibleForFreeNft = Config.getInstance().get().eligibleOnlyForFreeNft;
		if (!user?.userAddress) return;

		const isEligibleForRewards = eligibleForRewards.includes(user.userAddress);
		const isEligibleForFreeNft = eligibleForFreeNft.includes(user.userAddress);

		PopupStore.getInstance().isEligibleForFreeNft = isEligibleForFreeNft;
		PopupStore.getInstance().isEligibleForRewards = isEligibleForRewards || isEligibleForFreeNft;
	}

	// Get a new token if the role of the user if different of the role of the jwt token
	private refreshToken(user: IUser | null) {
		const token = JwtStore.getInstance().accessToken;
		if (!user || !token) return;
		const decodedToken = jwt.decode(token) as IJwtToken;
		if (!user.app_roles.every((userRole) => decodedToken.app_roles.some((tokenRole) => tokenRole.level === userRole.level))) {
			JwtStore.getInstance().refreshAccessToken();
		}
	}

	private onJwtTokenChange() {
		JwtStore.getInstance().onChange((jwtPair) => {
			const decodedToken = jwt.decode(jwtPair.accessToken) as JwtPayload;
			if (decodedToken["userAddress"]) {
				UserStore.getInstance().loadUser();
				return;
			}
			UserStore.getInstance().setUser(null);
		});
	}

	private async onSignInOrSignOutWallet(walletData: IWalletData, tokenUserAddress: string) {
		if (walletData.userAddress) {
			if (walletData.userAddress !== tokenUserAddress) {
				const jwtPair = await JwtStore.getInstance().signin();
				if (!jwtPair?.accessToken) {
					await Wallet.getInstance().disconnect();
				}
			}
			return;
		}
		await JwtStore.getInstance().presign();
	}

	private async onRefreshWallet(walletData: IWalletData, tokenUserAddress: string) {
		if (tokenUserAddress === walletData.userAddress) {
			await UserStore.getInstance().loadUser();
		}
	}

	private async onChangeAccount(walletData: IWalletData, tokenUserAddress: string) {
		if (tokenUserAddress !== walletData.userAddress && Boolean(tokenUserAddress)) {
			await JwtStore.getInstance().presign();
		}
	}

	public disconnect() {
		Wallet.getInstance().disconnect();
		UserStore.getInstance().disconnect();
	}
}
