import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";

// Assets
import MinteedImage from "assets/images/minteed-social.png";
import TetuImage from "assets/images/tetu-social.png";
import AliceMoitieImage from "assets/images/alicemoitie.jpeg";
import IconoclastImage from "assets/images/iconoclast-social.png";
import { ReactComponent as WebImage } from "assets/images/icons/web.svg";
import { ReactComponent as Instagram } from "assets/images/icons/instagram.svg";
import { ReactComponent as Twitter } from "assets/images/icons/twitter.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {};

export default class Social extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<p className={classes["title"]}>
					<I18n map="pages.home.social.title" />
				</p>

				<div className={classes["social-container"]}>
					<div className={classes["social-box"]}>
						<img src={AliceMoitieImage} className={classes["image"]} alt="Alice moitié" />

						<div className={classes["social-icons"]}>
							<a href="https://twitter.com/alicemoitie" className={classes["link"]} target="_blank" rel="noreferrer">
								<Twitter />
							</a>

							<a href="https://www.instagram.com/alicemoitie" className={classes["link"]} target="_blank" rel="noreferrer">
								<Instagram />
							</a>
						</div>
					</div>

					<div className={classes["social-box"]}>
						<img src={TetuImage} className={classes["image"]} alt="Alice moitié" />

						<div className={classes["social-icons"]}>
							<a href="https://twitter.com/TETUmag" className={classes["link"]} target="_blank" rel="noreferrer">
								<Twitter />
							</a>

							<a href="https://www.instagram.com/tetumag" className={classes["link"]} target="_blank" rel="noreferrer">
								<Instagram />
							</a>
						</div>
					</div>

					<div className={classes["social-box"]}>
						<img src={IconoclastImage} className={classes["image"]} alt="Alice moitié" />

						<div className={classes["social-icons"]}>
							<a href="https://iconoclastimage.tv" className={classes["link"]} target="_blank" rel="noreferrer">
								<WebImage />
							</a>

							<a
								href="https://www.instagram.com/iconoclast_image"
								className={classes["link"]}
								target="_blank"
								rel="noreferrer">
								<Instagram />
							</a>
						</div>
					</div>

					<div className={classes["social-box"]}>
						<img src={MinteedImage} className={classes["image"]} alt="Alice moitié" />

						<div className={classes["social-icons"]}>
							<a href="https://twitter.com/MinteedL" className={classes["link"]} target="_blank" rel="noreferrer">
								<Twitter />
							</a>

							<a
								href="https://www.instagram.com/minteed.collection"
								className={classes["link"]}
								target="_blank"
								rel="noreferrer">
								<Instagram />
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
