import { Component, RefObject } from "react";

// Components
import I18n from "Components/Elements/I18n";
import YearlySet from "./YearlySet";
import SemesterSet from "./SemesterSet";
import SeasonSet from "./SeasonSet";
import MintModal, { EMintModalType } from "Components/Materials/MintModal";

// Assets
import TetuMagazine from "assets/images/tetu-ad-magazine.png";

// Styles
import classes from "./classes.module.scss";
import Config from "Configs/Config";

type IProps = {
	customRef: RefObject<HTMLDivElement>;
};
type IState = {
	isMintModalOpen: boolean;
	mintModalType: EMintModalType;
};

export default class TreatsAndRewards extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isMintModalOpen: false,
			mintModalType: EMintModalType.YEARLY_SET,
		};
		this.openMintModal = this.openMintModal.bind(this);
		this.closeMintModal = this.closeMintModal.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]} ref={this.props.customRef}>
				<YearlySet onMintButtonClick={this.openMintModal} />

				<SemesterSet
					title={<I18n map="treats_and_rewards.semester_set.part_one.title" />}
					descriptionPartOne={<I18n map="treats_and_rewards.semester_set.part_one.description_part_one" />}
					descriptionPartTwo={<I18n map="treats_and_rewards.semester_set.part_one.description_part_two" />}
					descriptionPartThree={<I18n map="treats_and_rewards.semester_set.part_one.description_part_three" />}
					onMintButtonClick={this.openMintModal}
					mintType={EMintModalType.SEMESTER_SET_1}
				/>

				<SemesterSet
					title={<I18n map="treats_and_rewards.semester_set.part_two.title" />}
					descriptionPartOne={<I18n map="treats_and_rewards.semester_set.part_two.description_part_one" />}
					descriptionPartTwo={<I18n map="treats_and_rewards.semester_set.part_two.description_part_two" />}
					descriptionPartThree={<I18n map="treats_and_rewards.semester_set.part_two.description_part_three" />}
					onMintButtonClick={this.openMintModal}
					mintType={EMintModalType.SEMESTER_SET_2}
				/>

				<SeasonSet
					title={<I18n map="treats_and_rewards.season_set.part_one.title" />}
					descriptionPartOne={<I18n map="treats_and_rewards.season_set.part_one.description_part_one" />}
					descriptionPartTwo={<I18n map="treats_and_rewards.season_set.part_one.description_part_two" />}
					onMintButtonClick={this.openMintModal}
					mintType={EMintModalType.SEASON_SET_1}
				/>

				<SeasonSet
					title={<I18n map="treats_and_rewards.season_set.part_two.title" />}
					descriptionPartOne={<I18n map="treats_and_rewards.season_set.part_two.description_part_one" />}
					descriptionPartTwo={<I18n map="treats_and_rewards.season_set.part_two.description_part_two" />}
					onMintButtonClick={this.openMintModal}
					mintType={EMintModalType.SEASON_SET_2}
				/>

				<SeasonSet
					title={<I18n map="treats_and_rewards.season_set.part_three.title" />}
					descriptionPartOne={<I18n map="treats_and_rewards.season_set.part_three.description_part_one" />}
					descriptionPartTwo={<I18n map="treats_and_rewards.season_set.part_three.description_part_two" />}
					onMintButtonClick={this.openMintModal}
					mintType={EMintModalType.SEASON_SET_3}
				/>

				<SeasonSet
					title={<I18n map="treats_and_rewards.season_set.part_four.title" />}
					descriptionPartOne={<I18n map="treats_and_rewards.season_set.part_four.description_part_one" />}
					descriptionPartTwo={<I18n map="treats_and_rewards.season_set.part_four.description_part_two" />}
					onMintButtonClick={this.openMintModal}
					mintType={EMintModalType.SEASON_SET_4}
				/>

				<div className={classes["tetu-ad"]}>
					<div className={classes["tetu-infos"]}>
						<p className={classes["tetu-title"]}>têtu·</p>
						<p className={classes["tetu-description"]}>
							<I18n map="treats_and_rewards.tetu_ad_description" />
						</p>
					</div>

					<img src={TetuMagazine} alt="Tetu magazine" className={classes["tetu-magazine"]} />
				</div>

				<MintModal
					isSetAvailable={this.isSetAvailable()}
					type={this.state.mintModalType}
					isOpen={this.state.isMintModalOpen}
					onCloseModal={this.closeMintModal}
				/>
			</div>
		);
	}

	private openMintModal(mintModalType: EMintModalType) {
		this.setState({ isMintModalOpen: true, mintModalType });
	}

	private closeMintModal() {
		this.setState({ isMintModalOpen: false });
	}

	private isSetAvailable() {
		const now = new Date();
		let setComingSoonDate;

		switch (this.state.mintModalType) {
			case EMintModalType.YEARLY_SET:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.yearlySet.end;
				break;
			case EMintModalType.SEMESTER_SET_1:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.semesterSet.partOne.end;
				break;
			case EMintModalType.SEMESTER_SET_2:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.semesterSet.partTwo.end;
				break;
			case EMintModalType.SEASON_SET_1:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partOne.end;
				break;
			case EMintModalType.SEASON_SET_2:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partTwo.end;
				break;
			case EMintModalType.SEASON_SET_3:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partThree.end;
				break;
			case EMintModalType.SEASON_SET_4:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partFour.end;
				break;

			default:
				return false;
		}

		return now.getTime() > new Date(setComingSoonDate).getTime();
	}
}
