import { Component } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import Module from "Components/Elements/Module";
import Dropdown from "Components/Materials/Dropdown";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {};

export default class FAQ extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<p className={classes["title"]}>
					<I18n map="faq.title" />
				</p>
				<Dropdown label={<I18n map="faq.question_1.title" />}>
					<p className={classes["dropdown-text"]}>
						<I18n map="faq.question_1.text" />
					</p>
				</Dropdown>

				<Dropdown label={<I18n map="faq.question_2.title" />}>
					<p className={classes["dropdown-text"]}>
						<I18n map="faq.question_2.text" />
						<span className={classes["spacer"]}>
							<I18n map="faq.question_2.text_2" />
						</span>
						<NavLink to={Module.config.pages.TutoCreateWallet.props.path}>
							<span className={classNames(classes["spacer"], classes["link"])}>
								<I18n map="faq.question_2.text_3" />
							</span>
						</NavLink>
					</p>
				</Dropdown>

				<Dropdown label={<I18n map="faq.question_3.title" />}>
					<p className={classes["dropdown-text"]}>
						<I18n map="faq.question_3.text" />
						<span className={classes["spacer"]}>
							<I18n map="faq.question_3.text_2" />
						</span>
						<span className={classes["spacer"]}>
							<I18n map="faq.question_3.text_3" />
						</span>
						<span className={classes["spacer"]}>
							<I18n map="faq.question_3.text_4" />
						</span>
						<span className={classes["spacer"]}>
							<I18n map="faq.question_3.text_5" />
						</span>
					</p>
				</Dropdown>

				<Dropdown label={<I18n map="faq.question_4.title" />}>
					<p className={classes["dropdown-text"]}>
						<I18n map="faq.question_4.text" />
						<span className={classes["spacer"]}>
							<I18n map="faq.question_4.text_2" />
						</span>
					</p>
				</Dropdown>
			</div>
		);
	}
}
