import React, { ReactNode } from "react";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";

// Stores
import WindowStore from "Stores/WindowStore";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	onOpen?: (isOpen: boolean) => void;
	closeAction?: (closabled: () => void) => void;
	children?: ReactNode;
	className?: string;
	childrenClassName?: string;
};

type IState = {
	isOpen: boolean;
	dropdownHeight: number;
	animate: boolean;
};

export default class ViewMore extends React.Component<IProps, IState> {
	private removeOnresize = () => {};
	private contentRef = React.createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: false,
			dropdownHeight: 0,
			animate: true,
		};

		this.toggle = this.toggle.bind(this);
	}

	public override render(): JSX.Element | null {
		return (
			<div
				className={classNames(classes["root"], this.props.className)}
				data-open={this.state.isOpen}
				data-animate={this.state.animate}>
				<div
					className={classes["dropdown-content-container"]}
					style={{ height: this.state.dropdownHeight + "px" }}
					ref={this.contentRef}>
					<div className={classNames(classes["dropdown-content"], this.props.childrenClassName)}>{this.props.children}</div>
				</div>
				<div className={classes["dropdown-header-container"]} onClick={this.toggle}>
					{this.state.isOpen ? <I18n map="general_text.close" /> : <I18n map="general_text.view_more" />}
				</div>
			</div>
		);
	}

	public override componentDidMount() {
		this.removeOnresize = WindowStore.getInstance().onResize(() => this.onResize());
		if (!this.props.closeAction) return;
		this.props.closeAction(() => this.close());
	}

	public override componentWillUnmount() {
		this.removeOnresize();
	}

	private close() {
		this.setState({
			dropdownHeight: 0,
			isOpen: false,
			animate: true,
		});
	}

	private onResize() {
		if (!this.state.isOpen) return;
		this.setState(
			{
				dropdownHeight: 0,
				animate: false,
			},
			() => {
				let dropdownHeight = 0;
				if (this.state.isOpen) dropdownHeight = this.contentRef.current?.scrollHeight ?? 0;
				this.setState({
					dropdownHeight,
					animate: false,
				});
			},
		);
	}

	private toggle() {
		if (this.props.onOpen && !this.state.isOpen) {
			this.props.onOpen(false);
		}

		let dropdownHeight = 0;
		if (!this.state.isOpen) dropdownHeight = this.contentRef.current?.scrollHeight ?? 0;

		this.setState({
			dropdownHeight,
			isOpen: !this.state.isOpen,
			animate: true,
		});
	}
}
