import { MichelsonMap } from "@taquito/taquito";
import { char2Bytes } from "@taquito/utils";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import BigNumber from "Services/BigNumber";
import LightCache from "Services/LightCache";
import BaseContract from "./BaseContract";

export type IAssetContract = {
	quantity: number;
	quantity_reserved: number;
	initial_price: string;
	is_mintable: boolean;
	max_per_wallet: number;
	currency: { xTZ: null };
	salemode: { fCFS: null };
	allocations: MichelsonMap<any, any>;
	allocations_decimals: number;
	metas: MichelsonMap<any, any>;
	uuid: string;
};

export default class CollectionContract extends BaseContract {
	protected readonly collectionContractCache: LightCache;
	protected static readonly QUERY_DELAY = 8;
	protected static readonly ALLOCATIONS_DECIMALS = 4;

	constructor() {
		super();
		this.collectionContractCache = LightCache.getNewNameSpace();
	}

	public async updateMetaData(asset: IAsset, openMint: boolean) {
		const newMetaUri = char2Bytes(asset.metadataUri);
		const newMeta = MichelsonMap.fromLiteral({
			"": newMetaUri,
		});

		const revenueSharings: { [key: string]: number } = {};

		if (asset.assetRevenueSharings) {
			asset.assetRevenueSharings.forEach((revenueSharing) => {
				revenueSharings[revenueSharing.address] =
					(revenueSharing.percentage / 100) * Math.pow(10, CollectionContract.ALLOCATIONS_DECIMALS);
			});
		}

		try {
			return await this.contract.methodsObject
				.updateToken({
					id: asset.tokenId,
					new_metadata: newMeta,
					new_price: asset.mintPrice,
					new_allocations: [MichelsonMap.fromLiteral(revenueSharings), CollectionContract.ALLOCATIONS_DECIMALS],
					new_is_mintable: openMint,
				})
				.send();
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}

	public async generateOnChainAsset(asset: IAsset) {
		const revenueSharings: { [key: string]: number } = {};

		if (asset.assetRevenueSharings) {
			asset.assetRevenueSharings.forEach((revenueSharing) => {
				revenueSharings[revenueSharing.address] =
					(revenueSharing.percentage / 100) * Math.pow(10, CollectionContract.ALLOCATIONS_DECIMALS);
			});
		}

		const assetParams: IAssetContract = {
			quantity: asset.totalSupply,
			quantity_reserved: asset.amountReserved ?? 0,
			initial_price: asset.mintPrice,
			is_mintable: true,
			max_per_wallet: asset.maxMintPerWallet ?? 1,
			currency: { xTZ: null },
			salemode: { fCFS: null },
			allocations: MichelsonMap.fromLiteral(revenueSharings),
			allocations_decimals: CollectionContract.ALLOCATIONS_DECIMALS,
			metas: MichelsonMap.fromLiteral({
				"": char2Bytes(asset.metadataUri),
			}),
			uuid: asset.uuid,
		};

		try {
			return await this.contract.methodsObject.premint([assetParams]).send();
		} catch (err) {
			console.error(err);
		}
	}

	public async mintAsset(assetContractKeys: number, assetQuantities: number, assetPrice: string) {
		const mintAsset: any = {};
		mintAsset[assetContractKeys] = assetQuantities;
		const targetedAsset = MichelsonMap.fromLiteral(mintAsset);
		try {
			return await this.contract.methodsObject
				.mint(targetedAsset)
				.send({ amount: BigNumber.from(assetPrice).mul(assetQuantities).formatUnits(6) });
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}

	public async mintBatchAsset(assetContractKeys: number[], assetQuantities: number, assetPrice: string) {
		const mintAssets: any = {};

		assetContractKeys.forEach((key) => {
			mintAssets[key] = assetQuantities;
		});

		const targetedAssets = MichelsonMap.fromLiteral(mintAssets);

		try {
			return await this.contract.methodsObject.mint(targetedAssets).send({
				amount: BigNumber.from(assetPrice)
					.mul(assetQuantities * assetContractKeys.length)
					.formatUnits(6),
			});
		} catch (err: any) {
			throw new Error(err?.message);
		}
	}
}
