import Config from "Configs/Config";
import BaseApiService from "Api/BaseApiService";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import { CollectionAssetStatus } from "Entities/CollectionAsset/enums/collectionAssetStatus";
import { IPaginatedResult } from "Entities/PaginatedResult";
import { IPaginatedParams } from "Entities/PaginatedParam";
interface IPostParams {
	body: FormData;
}

interface IPutParams {
	collectionAssetId: number;
	body: FormData;
}

interface IGetParams {
	collectionAssetId: number;
}

interface IGetAllParams {
	appCollection?: {
		id: number;
	};
	status?: CollectionAssetStatus;
	notDisplayed?: Date | null;
}

type ISortParams = {
	[key in keyof IAsset | "updatedAt" | "createdAt"]?: "asc" | "desc";
};

export default class CollectionAsset extends BaseApiService {
	static instance: CollectionAsset;
	protected static readonly baseUrl = `${Config.getInstance().get().api.authFactory}/${
		Config.getInstance().get().app
	}/app-collection-asset`;

	static getInstance() {
		if (!CollectionAsset.instance) {
			CollectionAsset.instance = new CollectionAsset();
		}

		return CollectionAsset.instance;
	}

	public async getAll(params: IGetAllParams, pagination?: IPaginatedParams, sort?: ISortParams): Promise<IPaginatedResult<IAsset>> {
		const url = new URL(CollectionAsset.baseUrl);

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}

		if (pagination) {
			url.searchParams.set("p", JSON.stringify(pagination));
		}

		if (sort) {
			url.searchParams.set("s", JSON.stringify(sort));
		}

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getRandom(count: number, params: IGetAllParams) {
		const url = new URL(CollectionAsset.baseUrl + "/random");

		url.searchParams.set("count", count.toString());

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getByMostLiked(params: IGetAllParams, pagination?: IPaginatedParams): Promise<IPaginatedResult<IAsset>> {
		const url = new URL(CollectionAsset.baseUrl + "/mostLiked");

		if (params) {
			url.searchParams.set("q", JSON.stringify(params));
		}
		if (pagination) {
			url.searchParams.set("p", JSON.stringify(pagination));
		}
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async get(params: IGetParams): Promise<IAsset | null> {
		const url = new URL(`${CollectionAsset.baseUrl}/${params.collectionAssetId}`);

		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getInCollection(collectionId: number): Promise<IAsset[] | undefined> {
		const url = new URL(CollectionAsset.baseUrl);
		url.searchParams.set(
			"q",
			JSON.stringify({
				appCollection: collectionId,
			}),
		);
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async post(params: IPostParams): Promise<IAsset> {
		const url = new URL(`${CollectionAsset.baseUrl}/`);

		try {
			return this.postFormDataRequest(url, params.body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async put(params: IPutParams): Promise<IAsset> {
		const url = new URL(`${CollectionAsset.baseUrl}/${params.collectionAssetId}`);
		try {
			return this.putFormDataRequest(url, params.body);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}

	public async getNftsCreatedCountByUserAddress(userAddress: string): Promise<number> {
		const url = new URL(`${CollectionAsset.baseUrl}/count`);
		url.searchParams.set(
			"q",
			JSON.stringify({
				appCollection: {
					owner: { userAddress },
				},
			}),
		);
		try {
			return this.getRequest(url);
		} catch (err) {
			this.onError(err);
			return Promise.reject(err);
		}
	}
}
