import classNames from "classnames";

// Components
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplate/DefaultTemplate";
import I18n from "Components/Elements/I18n";

// Assets
import TutoStep1 from "assets/images/tuto-step-1.gif";
import TutoStep2 from "assets/images/tuto-step-2.gif";
import TutoStep3 from "assets/images/tuto-step-3.gif";
import TutoStep4 from "assets/images/tuto-step-4.gif";
import TutoStep5 from "assets/images/tuto-step-5.gif";
import TutoStep6 from "assets/images/tuto-step-6.gif";

// Styles
import classes from "./classes.module.scss";
import Step from "./Step";

type IProps = {};
type IState = {};

export default class CreateWalletTuto extends BasePage<IProps, IState> {
	override render() {
		return (
			<DefaultTemplate title={I18n.translate("pages.tuto.page_title")}>
				<div className={classes["root"]}>
					<h1 className={classes["title"]}>
						<I18n map="pages.tuto.title" />
					</h1>

					<p className={classNames(classes["text"], classes["center"])}>
						<I18n map="pages.tuto.intro" />
					</p>

					<Step
						title={<I18n map="pages.tuto.step_1.title" />}
						image={TutoStep1}
						text={
							<>
								<I18n map="pages.tuto.step_1.text" />
								&nbsp;
								<a href="https://kukai.app" className={classes["link"]} target="_blank" rel="noreferrer">
									<I18n map="pages.tuto.step_1.kukai" />
								</a>
								&nbsp;
								<I18n map="pages.tuto.step_1.text_2" />
							</>
						}
					/>

					<Step
						title={<I18n map="pages.tuto.step_2.title" />}
						image={TutoStep2}
						text={<I18n map="pages.tuto.step_2.text" />}
						list={[
							<I18n map="pages.tuto.step_2.text_2" />,
							<I18n map="pages.tuto.step_2.text_3" />,
							<I18n map="pages.tuto.step_2.text_4" />,
							<I18n map="pages.tuto.step_2.text_5" />,
						]}
					/>

					<Step title={<I18n map="pages.tuto.step_3.title" />} image={TutoStep3} text={<I18n map="pages.tuto.step_3.text" />} />

					<Step
						title={<I18n map="pages.tuto.step_4.title" />}
						image={TutoStep4}
						text={<I18n map="pages.tuto.step_4.text" />}
						list={[
							<I18n map="pages.tuto.step_4.text_2" />,
							<I18n map="pages.tuto.step_4.text_3" />,
							<I18n map="pages.tuto.step_4.text_4" />,
							<I18n map="pages.tuto.step_4.text_5" />,
							<I18n map="pages.tuto.step_4.text_6" />,
							<I18n map="pages.tuto.step_4.text_7" />,
						]}
					/>

					<Step
						title={<I18n map="pages.tuto.step_5.title" />}
						image={TutoStep5}
						text={<I18n map="pages.tuto.step_5.text" />}
						list={[
							<I18n map="pages.tuto.step_5.text_2" />,
							<I18n map="pages.tuto.step_5.text_3" />,
							<I18n map="pages.tuto.step_5.text_4" />,
							<I18n map="pages.tuto.step_5.text_5" />,
							<I18n map="pages.tuto.step_5.text_6" />,
						]}
					/>

					<Step
						title={<I18n map="pages.tuto.step_6.title" />}
						image={TutoStep6}
						text={<I18n map="pages.tuto.step_6.text" />}
						list={[
							<I18n map="pages.tuto.step_6.text_2" />,
							<I18n map="pages.tuto.step_6.text_3" />,
							<I18n map="pages.tuto.step_6.text_4" />,
							<I18n map="pages.tuto.step_6.text_5" />,
							<I18n map="pages.tuto.step_6.text_6" />,
							<I18n map="pages.tuto.step_6.text_7" />,
						]}
					/>

					<div className={classes["divider"]} />
				</div>
			</DefaultTemplate>
		);
	}
}
