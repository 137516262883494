import { ReactNode } from "react";

// Components
import { ETreatsAndRewardsPart } from "Components/Materials/MintModal";

// Configs
import Config from "Configs/Config";

// Assets
import FigEmoji from "assets/images/icons/fig-emoji.png";
import ArtichokeEmoji from "assets/images/icons/artichoke-emoji.png";

// Light-Version
import JanuaryLightImage from "assets/images/calandar/light-version/january-min.jpg";
import FebruaryLightImage from "assets/images/calandar/light-version/february-min.jpg";
import MarchLightImage from "assets/images/calandar/light-version/march-min.jpg";
import AprilLightImage from "assets/images/calandar/light-version/april-min.jpg";
import MayLightImage from "assets/images/calandar/light-version/may-min.jpg";
import JuneLightImage from "assets/images/calandar/light-version/june-min.jpg";
import JulyLightImage from "assets/images/calandar/light-version/july-min.jpg";
import AugustLightImage from "assets/images/calandar/light-version/august-min.jpg";
import SeptemberLightImage from "assets/images/calandar/light-version/september-min.jpg";
import OctoberLightImage from "assets/images/calandar/light-version/october-min.jpg";
import NovemberLightImage from "assets/images/calandar/light-version/november-min.jpg";
import DecemberLightImage from "assets/images/calandar/light-version/december-min.jpg";

// Calandar NFTs
import JanuaryImage from "assets/images/calandar/january.jpg";
import FebruaryImage from "assets/images/calandar/february.jpg";
import MarchImage from "assets/images/calandar/march.jpg";
import AprilImage from "assets/images/calandar/april.jpg";
import MayImage from "assets/images/calandar/may.jpg";
import JuneImage from "assets/images/calandar/june.jpg";
import JulyImage from "assets/images/calandar/july.jpg";
import AugustImage from "assets/images/calandar/august.jpg";
import SeptemberImage from "assets/images/calandar/september.jpg";
import OctoberImage from "assets/images/calandar/october.jpg";
import NovemberImage from "assets/images/calandar/november.jpg";
import DecemberImage from "assets/images/calandar/december.jpg";

// Blurred images
import JanuaryBlurredImage from "assets/images/calandar/blur/january.png";
import FebruaryBlurredImage from "assets/images/calandar/blur/february.png";
import MarchBlurredImage from "assets/images/calandar/blur/march.png";
import AprilBlurredImage from "assets/images/calandar/blur/april.png";
import MayBlurredImage from "assets/images/calandar/blur/may.png";
import JuneBlurredImage from "assets/images/calandar/blur/june.png";
import JulyBlurredImage from "assets/images/calandar/blur/july.png";
import AugustBlurredImage from "assets/images/calandar/blur/august.png";
import SeptemberBlurredImage from "assets/images/calandar/blur/september.png";
import OctoberBlurredImage from "assets/images/calandar/blur/october.png";
import NovemberBlurredImage from "assets/images/calandar/blur/november.png";
import DecemberBlurredImage from "assets/images/calandar/blur/december.png";

export interface ICalandarNft {
	id: string;
	image: string;
	blurredImage: string;
	month: string;
	emoji: string | ReactNode;
	modelsName: string[];
	socialMediaLinks: string[];
	dateOfCommingSoon: string;
	seasonTreatsAndRewardsType: ETreatsAndRewardsPart;
	semesterTreatsAndRewardsType: ETreatsAndRewardsPart;
}

export enum EMonths {
	JANUARY = "january",
	FEBRUARY = "february",
	MARCH = "march",
	APRIL = "april",
	MAY = "may",
	JUNE = "june",
	JULY = "july",
	AUGUST = "august",
	SEPTEMBER = "september",
	OCTOBER = "october",
	NOVEMBER = "november",
	DECEMBER = "december",
}

export const calandarNfts: ICalandarNft[] = [
	{
		id: Config.getInstance().get().nfts.january.id,
		emoji: "🍎",
		month: EMonths.JANUARY,
		modelsName: ["Sandor Funtek"],
		socialMediaLinks: ["https://www.instagram.com/sandorfuntek/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.january.coming_soon.end,
		image: JanuaryImage,
		blurredImage: JanuaryBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
	},
	{
		id: Config.getInstance().get().nfts.february.id,
		emoji: <img src={ArtichokeEmoji} alt="artichoke emoji" />,
		month: EMonths.FEBRUARY,
		modelsName: ["Roman Freud"],
		socialMediaLinks: ["https://www.instagram.com/romanfrdd/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.february.coming_soon.end,
		image: FebruaryImage,
		blurredImage: FebruaryBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
	},
	{
		id: Config.getInstance().get().nfts.march.id,
		emoji: "🍐",
		month: EMonths.MARCH,
		modelsName: ["Marina Rollman"],
		socialMediaLinks: ["https://www.instagram.com/marinarollman/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.march.coming_soon.end,
		image: MarchImage,
		blurredImage: MarchBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
	},
	{
		id: Config.getInstance().get().nfts.april.id,
		emoji: "🥕",
		month: EMonths.APRIL,
		modelsName: ["Marina Benenge", "Stessy Emelie"],
		socialMediaLinks: ["https://www.instagram.com/mariana_benenge/?hl=fr", "https://www.instagram.com/stessyemelie/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.april.coming_soon.end,
		image: AprilImage,
		blurredImage: AprilBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
	},
	{
		id: Config.getInstance().get().nfts.may.id,
		emoji: "🍌",
		month: EMonths.MAY,
		modelsName: ["Pewiyard"],
		socialMediaLinks: ["https://www.instagram.com/pewiyard/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.may.coming_soon.end,
		image: MayImage,
		blurredImage: MayBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
	},
	{
		id: Config.getInstance().get().nfts.june.id,
		emoji: "🍋",
		month: EMonths.JUNE,
		modelsName: ["Emile Pony"],
		socialMediaLinks: ["https://www.instagram.com/emilepony/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.june.coming_soon.end,
		image: JuneImage,
		blurredImage: JuneBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_ONE,
	},
	{
		id: Config.getInstance().get().nfts.july.id,
		emoji: "🍓",
		month: EMonths.JULY,
		modelsName: ["Lancelot Prat"],
		socialMediaLinks: ["https://www.instagram.com/lancelotprat/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.july.coming_soon.end,
		image: JulyImage,
		blurredImage: JulyBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_THREE,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
	},
	{
		id: Config.getInstance().get().nfts.august.id,
		emoji: "🍇",
		month: EMonths.AUGUST,
		modelsName: ["Stephane Bak"],
		socialMediaLinks: ["https://www.instagram.com/stephanebak/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.august.coming_soon.end,
		image: AugustImage,
		blurredImage: AugustBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_THREE,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
	},
	{
		id: Config.getInstance().get().nfts.september.id,
		emoji: <img src={FigEmoji} alt="fig emoji" />,
		month: EMonths.SEPTEMBER,
		modelsName: ["Jean Lemersre"],
		socialMediaLinks: ["https://www.instagram.com/jeanlemersre/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.september.coming_soon.end,
		image: SeptemberImage,
		blurredImage: SeptemberBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_THREE,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
	},
	{
		id: Config.getInstance().get().nfts.october.id,
		emoji: "🍠",
		month: EMonths.OCTOBER,
		modelsName: ["Lalla Rami"],
		socialMediaLinks: ["https://www.instagram.com/lalla.rami/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.october.coming_soon.end,
		image: OctoberImage,
		blurredImage: OctoberBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_FOUR,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
	},
	{
		id: Config.getInstance().get().nfts.november.id,
		emoji: "🎃",
		month: EMonths.NOVEMBER,
		modelsName: ["Yassin"],
		socialMediaLinks: ["https://www.instagram.com/yassinchekkouh/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.november.coming_soon.end,
		image: NovemberImage,
		blurredImage: NovemberBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_FOUR,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
	},
	{
		id: Config.getInstance().get().nfts.december.id,
		emoji: "🍊",
		month: EMonths.DECEMBER,
		modelsName: ["Guillaume Diop"],
		socialMediaLinks: ["https://www.instagram.com/guillaumediop/?hl=fr"],
		dateOfCommingSoon: Config.getInstance().get().nfts.december.coming_soon.end,
		image: DecemberImage,
		blurredImage: DecemberBlurredImage,
		seasonTreatsAndRewardsType: ETreatsAndRewardsPart.PART_FOUR,
		semesterTreatsAndRewardsType: ETreatsAndRewardsPart.PART_TWO,
	},
];

export const lightNfts = [
	JanuaryLightImage,
	FebruaryLightImage,
	MarchLightImage,
	AprilLightImage,
	MayLightImage,
	JuneLightImage,
	JulyLightImage,
	AugustLightImage,
	SeptemberLightImage,
	OctoberLightImage,
	NovemberLightImage,
	DecemberLightImage,
];
