import * as api from "@tzkt/sdk-api";
import Config from "Configs/Config";
import LightCache from "Services/LightCache";
import BaseContract from "./BaseContract";

api.defaults.baseUrl = Config.getInstance().get().blockchain.tezos.tzktApi;

export default class FactoryContract extends BaseContract {
	protected static ctx: FactoryContract;
	protected readonly minteedContractCache: LightCache;
	protected static readonly QUERY_DELAY = 8;
	protected static readonly ALLOCATIONS_DECIMALS = 4;

	constructor() {
		super();
		this.minteedContractCache = LightCache.getNewNameSpace();
	}

	public async getCollectionByUuid(uuid: string) {
		const storage: Storage = await this.contract.storage();
		return await storage["uuid_collections"].get(uuid);
	}

	/**
	 * A collection is generated on-chain when the first asset of some collection
	 * see its "openMint" prpoerty being switched to true
	 */
}
