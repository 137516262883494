import { Component, Fragment, ReactNode } from "react";
import { NavigateFunction } from "react-router-dom";

// Components
import Module from "Components/Elements/Module";
import I18n from "Components/Elements/I18n";

// Entities
import { ICalandarNft } from "Entities/CalandarNft";

// Assets
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	nft: ICalandarNft;
	navigate: NavigateFunction;
};
type IState = {};

export default class GalleryHeader extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.onCrossClick = this.onCrossClick.bind(this);
	}
	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["nft-infos-container"]}>
					<p className={classes["nft-title"]}>
						<span className={classes["emoji"]}>{this.props.nft.emoji}</span>
						&nbsp;
						<I18n map={`nfts.${this.props.nft.month}.month`} />
						&nbsp;
						<I18n map="general_text.with" />
						&nbsp;
						{this.getModelNames()}
					</p>
					<p className={classes["nft-location"]}>
						<I18n map={`nfts.${this.props.nft.month}.shot_location`} />
					</p>
				</div>

				<CrossIcon className={classes["cross-icon"]} onClick={this.onCrossClick} />
			</div>
		);
	}

	private onCrossClick() {
		this.props.navigate(Module.config.pages.Home.props.path);
	}

	private getModelNames() {
		const modelNames: ReactNode[] = [];

		this.props.nft.modelsName.forEach((name, index) => {
			const modelSocialUrl = this.props.nft.socialMediaLinks[index];

			modelNames.push(
				<Fragment key={name}>
					{index > 0 && (
						<>
							&nbsp;
							<I18n map="general_text.and" />
							&nbsp;
						</>
					)}
					<a href={modelSocialUrl} className={classes["social-link"]} target="_blank" rel="noreferrer">
						{name}
					</a>
				</Fragment>,
			);
		});

		return modelNames;
	}
}
