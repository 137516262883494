import { Component } from "react";
import classNames from "classnames";

// Assets
import StarShapeImage from "assets/images/star-shape.png";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	className?: string;
};
type IState = {};

export default class EndingDate extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classNames(classes["root"], this.props.className)}>
				<img src={StarShapeImage} className={classes["image"]} alt="ending date" />
				<div className={classes["text-container"]}>
					<p className={classes["text"]}>ENDS:</p>
					<p className={classes["text"]}>31.12.22</p>
				</div>
			</div>
		);
	}
}
