import { Component } from "react";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import { EMintModalType } from "Components/Materials/MintModal";

// Configs
import Config from "Configs/Config";

// Assets
import { ReactComponent as TezosIcon } from "assets/images/icons/tezos.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	onMintButtonClick: (mintModalType: EMintModalType) => void;
};
type IState = {};

export default class YearlySet extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onMintButtonClick = this.onMintButtonClick.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<p className={classes["set-title"]}>
					<I18n map="treats_and_rewards.yearly_set.title" />
				</p>

				<div className={classes["set-infos"]}>
					<p className={classes["set-description"]}>
						<span>
							<I18n map="treats_and_rewards.yearly_set.description_part_one" />
						</span>
						<span>
							<I18n map="treats_and_rewards.yearly_set.description_part_two" />
						</span>
						<span>
							<I18n map="treats_and_rewards.yearly_set.description_part_three" />
						</span>
						<span>
							<I18n map="treats_and_rewards.yearly_set.description_part_four" />
						</span>
					</p>

					{this.isSetAvailable() ? (
						<button className={classes["button"]} onClick={this.onMintButtonClick}>
							<span>
								<I18n map="general_text.mint" />
							</span>
							&nbsp;
							<span>
								{Config.getInstance().get().setsPrices.yearlySet} <TezosIcon className={classes["tezos-icon"]} />
							</span>
						</button>
					) : (
						<button className={classNames(classes["button"], classes["center"])}>
							<span>
								<I18n map="general_text.available_soon" />
							</span>
						</button>
					)}
				</div>
			</div>
		);
	}

	private onMintButtonClick() {
		this.props.onMintButtonClick(EMintModalType.YEARLY_SET);
	}

	private isSetAvailable() {
		const date = new Date(Config.getInstance().get().setsComingSoon.yearlySet.end);
		const now = new Date();

		return date.getTime() < now.getTime();
	}
}
