import { Component, ReactNode } from "react";
import classNames from "classnames";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	image: string;
	title: ReactNode;
	text: ReactNode;
	list?: ReactNode[];
};
type IState = {};

export default class Step extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<h2 className={classes["subtitle"]}>{this.props.title}</h2>

				<img src={this.props.image} className={classes["image"]} alt="" />

				<p className={classes["text"]}>{this.props.text}</p>

				{this.props.list && (
					<ul>
						{this.props.list.map((text, index) => (
							<li key={index}>
								<p className={classNames(classes["text"], classes["no-margin"])}>{text}</p>
							</li>
						))}
					</ul>
				)}
			</div>
		);
	}
}
