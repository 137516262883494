import { Component } from "react";

// Components
import I18nStore from "Components/Elements/I18n/I18nStore";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	lang: string;
};

export default class SwitchLanguage extends Component<IProps, IState> {
	private removeOnLangChange = () => {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			lang: I18nStore.getInstance().lang,
		};

		this.toggleLang = this.toggleLang.bind(this);
		this.onLangChange = this.onLangChange.bind(this);
	}

	override render() {
		return this.state.lang === "fr" ? (
			<div className={classes["root"]} data-lang="en" onClick={this.toggleLang}>
				🇬🇧
			</div>
		) : (
			<div className={classes["root"]} data-lang="fr" onClick={this.toggleLang}>
				🇫🇷
			</div>
		);
	}

	override componentDidMount() {
		this.removeOnLangChange = I18nStore.getInstance().onChange(this.onLangChange);
	}

	override componentWillUnmount() {
		this.removeOnLangChange();
	}

	private onLangChange() {
		this.setState({
			lang: I18nStore.getInstance().lang,
		});
	}

	private toggleLang(e: React.MouseEvent<HTMLDivElement>) {
		const lang: string = e.currentTarget.getAttribute("data-lang")!;
		I18nStore.getInstance().toggleTo(lang);
	}
}
