import classNames from "classnames";
import PoweredByMinteed from "Components/Elements/PoweredByMinteed";
import Header from "Components/Materials/Header";
import IsEligibleForRewardsPopup from "Components/Materials/IsEligibleForRewardsPopup";
import IsNotEligibleForRewardsPopup from "Components/Materials/IsNotEligibleForRewardsPopup";
import React, { ReactNode } from "react";
import JwtToken from "Stores/JwtStore";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	title: string;
	hideHeader?: boolean;
	noPadding?: boolean;
	noMaxWidth?: boolean;
	noPoweredByMinteed?: boolean;
	children?: ReactNode;
};
type IState = {};

export default class DefaultTemplate extends React.Component<IProps, IState> {
	public override render(): JSX.Element {
		return (
			<div
				className={classNames(classes["root"], {
					[classes["no-padding"]!]: this.props.noPadding,
					[classes["no-max-width"]!]: this.props.noMaxWidth,
				})}>
				{!this.props.hideHeader && <Header />}
				{this.props.children}
				{!this.props.noPoweredByMinteed && <PoweredByMinteed />}
				<IsEligibleForRewardsPopup />
				<IsNotEligibleForRewardsPopup />
			</div>
		);
	}

	override componentDidMount() {
		JwtToken.getInstance(); // Ask me about that, I need to explain it to Massi to find a better solution
		window.document.title = this.props.title;
	}
}
