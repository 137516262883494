import { ChangeEvent, Component, FormEvent, ReactNode } from "react";

// Components
import I18n from "Components/Elements/I18n";

// Configs
import Config from "Configs/Config";

// Stores
import Toasts from "Stores/Toasts";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	children: ReactNode;
};
type IState = {
	email: string;
};

export default class CommingSoonNewsletter extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			email: "",
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.onEmailChange = this.onEmailChange.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["newsletter"]}>
					<div className={classes["newsletter-container"]}>
						<p className={classes["title"]}>🤫 COMING SOON 🤫</p>
						<p className={classes["description"]}>
							<I18n map="pages.home.newsletter.description_1" />
							<br />
							<I18n map="pages.home.newsletter.description_2" />
						</p>

						<form className={classes["form"]} onSubmit={this.onSubmit}>
							<div className={classes["input-container"]}>
								<label className={classes["label"]}>Email</label>
								<input
									value={this.state.email}
									onChange={this.onEmailChange}
									className={classes["input"]}
									type="email"
									placeholder="Type here"
								/>
							</div>
							<button className={classes["button"]}>Sign-up</button>
						</form>
					</div>
				</div>

				<div className={classes["children-container"]}>{this.props.children}</div>
			</div>
		);
	}

	private async onSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		try {
			const apiRoute = Config.getInstance().get().api.authFactory;
			await fetch(`${apiRoute}/app-pending-audience/tetuAddUser`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: this.state.email,
				}),
			});

			this.showToast();
			this.resetEmailState();
		} catch (error) {
			console.log(error);
		}
	}

	private showToast() {
		Toasts.getInstance().open({
			title: <I18n map="toasts.thanks_subscribe" />,
			text: <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{this.state.email}</p>,
			time: 2000,
		});
	}

	private resetEmailState() {
		this.setState({ email: "" });
	}

	private onEmailChange(event: ChangeEvent<HTMLInputElement>) {
		this.setState({ email: event.target.value });
	}
}
