import { Component } from "react";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import ViewMore from "Components/Materials/ViewMore";
import ClaimRewards from "./ClaimRewards";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {};

export default class TetuDescriptionHeader extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<p className={classNames(classes["text"], classes["headline"], classes["spacer"])}>
					<I18n map="pages.home.header.text_1" />
				</p>

				<ClaimRewards />

				<p className={classNames(classes["text"], classes["spacer"])}>
					<I18n map="pages.home.header.text_2" />
					&nbsp;
					<span className={classes["bold"]}>
						<I18n map="pages.home.header.text_3" />
					</span>
					&nbsp;
					<I18n map="pages.home.header.text_4" />
				</p>

				<p className={classNames(classes["text"], classes["spacer"])}>
					<I18n map="pages.home.header.text_5" />
				</p>

				<p className={classes["text"]}>
					<I18n map="pages.home.header.text_6" />
				</p>

				<p className={classNames(classes["headline-bold"], classes["spacer"])}>
					<I18n map="pages.home.header.text_7" />
				</p>

				<p className={classes["headline-bold"]}>
					<I18n map="pages.home.header.text_8" />
				</p>

				<ViewMore className={classes["spacer"]} childrenClassName={classes["flex"]}>
					<div className={classes["left-container"]}>
						<p className={classes["text"]}>
							<I18n map="pages.home.header.left_container.text_1" />
						</p>

						<p className={classNames(classes["text"], classes["spacer"])}>
							<I18n map="pages.home.header.left_container.text_2" />
						</p>

						<p className={classNames(classes["text"], classes["spacer"])}>
							<I18n map="pages.home.header.left_container.text_3" />
						</p>

						<p className={classNames(classes["text"], classes["spacer"])}>
							<I18n map="pages.home.header.left_container.text_4" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.left_container.text_5" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.left_container.text_6" />
						</p>
					</div>
					<div className={classes["right-container"]}>
						<p className={classes["text"]}>
							<I18n map="pages.home.header.right_container.text_1" />
						</p>

						<p className={classNames(classes["text"], classes["spacer"])}>
							<I18n map="pages.home.header.right_container.text_2" />
						</p>

						<p className={classNames(classes["text"], classes["spacer"])}>
							<I18n map="pages.home.header.right_container.text_3" />
						</p>

						<p className={classNames(classes["text"], classes["spacer"])}>
							<I18n map="pages.home.header.right_container.text_4" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.right_container.text_5" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.right_container.text_6" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.right_container.text_7" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.right_container.text_8" />
						</p>
					</div>
				</ViewMore>
			</div>
		);
	}
}
