import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";
import MintModal, { EMintModalType, ETreatsAndRewardsPart } from "Components/Materials/MintModal";
import BuySeasonSet from "./BuySeasonSet";
import BuySemesterSet from "./BuySemesterSet";
import BuyYearlySet from "./BuyYearlySet";

// Configs
import Config from "Configs/Config";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	seasonSetPart: ETreatsAndRewardsPart;
	semesterSetPart: ETreatsAndRewardsPart;
	isSeasonSetAvailable: boolean;
	isSemesterSetAvailable: boolean;
	isYearlySetAvailable: boolean;
};
type IState = {
	isMintModalOpen: boolean;
	mintModalType: EMintModalType;
};

export default class BuySets extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isMintModalOpen: false,
			mintModalType: EMintModalType.YEARLY_SET,
		};
		this.openMintModal = this.openMintModal.bind(this);
		this.closeMintModal = this.closeMintModal.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<p className={classes["title"]}>
					<I18n map="buy_sets.title" />
				</p>
				<div className={classes["sets"]}>
					<BuySeasonSet
						onMintButtonClick={this.openMintModal}
						isAvailable={this.props.isSeasonSetAvailable}
						setPart={this.props.seasonSetPart}
					/>
					<BuySemesterSet
						onMintButtonClick={this.openMintModal}
						isAvailable={this.props.isSemesterSetAvailable}
						setPart={this.props.semesterSetPart}
					/>
					<BuyYearlySet onMintButtonClick={this.openMintModal} isAvailable={this.props.isYearlySetAvailable} />
				</div>

				<MintModal
					isSetAvailable={this.isSetAvailable()}
					type={this.state.mintModalType}
					isOpen={this.state.isMintModalOpen}
					onCloseModal={this.closeMintModal}
				/>
			</div>
		);
	}

	private isSetAvailable() {
		const now = new Date();
		let setComingSoonDate;

		switch (this.state.mintModalType) {
			case EMintModalType.YEARLY_SET:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.yearlySet.end;
				break;
			case EMintModalType.SEMESTER_SET_1:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.semesterSet.partOne.end;
				break;
			case EMintModalType.SEMESTER_SET_2:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.semesterSet.partTwo.end;
				break;
			case EMintModalType.SEASON_SET_1:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partOne.end;
				break;
			case EMintModalType.SEASON_SET_2:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partTwo.end;
				break;
			case EMintModalType.SEASON_SET_3:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partThree.end;
				break;
			case EMintModalType.SEASON_SET_4:
				setComingSoonDate = Config.getInstance().get().setsComingSoon.seasonSet.partFour.end;
				break;

			default:
				return false;
		}

		return now.getTime() > new Date(setComingSoonDate).getTime();
	}

	private openMintModal(mintModalType: EMintModalType) {
		this.setState({ isMintModalOpen: true, mintModalType });
	}

	private closeMintModal() {
		this.setState({ isMintModalOpen: false });
	}
}
