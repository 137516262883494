import { TezosToolkit } from "@taquito/taquito";
import Config from "Configs/Config";
import BigNumber from "Services/BigNumber";
import CollectionContract from "Services/Contracts/Tezos/CollectionContract";
import FactoryContract from "Services/Contracts/Tezos/FactoryContract";
import EventService from "Services/EventEmitter";
import Wallet from "Stores/Wallet";
import { OperationEntry, BlockResponse } from "@taquito/rpc";
import ProxyContract from "Services/Contracts/Tezos/ProxyContract";

type ConfirmOperationOptions = {
	initializedAt?: number;
	fromBlockLevel?: number;
	signal?: AbortSignal;
};

export type ITotalTokens = {
	lockedTokens: BigNumber;
	availableTokens: BigNumber;
};
export interface IContract {
	factoryContract: FactoryContract | null;
}

class EventEmitter extends EventService {}

export default class TzContract {
	private static ctx: TzContract;
	private _factoryContract: FactoryContract | null = null;
	private _proxyContract: ProxyContract | null = null;
	private _collectionContracts: { [key: string]: CollectionContract } = {};
	private readonly event = new EventEmitter();

	public get contractData(): IContract {
		return {
			factoryContract: this._factoryContract,
		};
	}

	public get factoryContract() {
		return this._factoryContract;
	}

	public get proxyContract() {
		return this._proxyContract;
	}

	public async collectionContract(contractAddress: string): Promise<CollectionContract> {
		if (this._collectionContracts[contractAddress]) {
			return this._collectionContracts[contractAddress]!;
		}
		const walletProvider = Wallet.getInstance().getWalletData().provider;
		const provider = walletProvider ?? new TezosToolkit(Config.getInstance().get().blockchain.tezos.rpc);
		const newInstance = new CollectionContract();
		await newInstance.init(contractAddress, provider);
		this._collectionContracts[contractAddress] = newInstance;
		return newInstance;
	}

	private constructor() {
		this.setContractData(Wallet.getInstance().getWalletData().provider);
		TzContract.ctx = this;
	}

	public static getInstance() {
		if (!TzContract.ctx) new this();
		return TzContract.ctx;
	}

	public onChange(callback: (contractData: IContract) => void) {
		this.event.on("tz-contract-change", callback);
		return () => {
			this.event.off("tz-contract-change", callback);
		};
	}

	public async setContractData(walletProvider: TezosToolkit | null) {
		const factoryAddress = Config.getInstance().get().blockchain.tezos.contracts.factory;
		const proxyAddress = Config.getInstance().get().blockchain.tezos.contracts.proxy;
		const provider = walletProvider ?? new TezosToolkit(Config.getInstance().get().blockchain.tezos.rpc);
		const factoryContract = new FactoryContract();
		await factoryContract.init(factoryAddress, provider);
		const proxyContract = new ProxyContract();
		await proxyContract.init(proxyAddress, provider);

		this._factoryContract = factoryContract;
		this._proxyContract = proxyContract;
		this._collectionContracts = {};

		for (const contractAddress of Object.keys(this._collectionContracts)) {
			const contract = new CollectionContract();
			await contract.init(contractAddress, provider);
		}

		this.event.emit("tz-contract-change", this.contractData);
	}

	public async confirmOperation(
		opHash: string,
		{ initializedAt, fromBlockLevel, signal }: ConfirmOperationOptions = {},
	): Promise<OperationEntry> {
		const walletProvider = Wallet.getInstance().getWalletData().provider;
		const tezos = walletProvider ?? new TezosToolkit(Config.getInstance().get().blockchain.tezos.rpc);
		const confirmTimeout = 120000;
		const syncInterval = 1000;
		if (!initializedAt) {
			initializedAt = Date.now();
		}

		if (initializedAt && initializedAt + confirmTimeout < Date.now()) {
			throw new Error("Confirmation polling timed out");
		}

		const startedAt: number = Date.now();
		let currentBlockLevel: number = 0;

		try {
			const currentBlock: any = await tezos.rpc.getBlock();

			currentBlockLevel = currentBlock.header.level;

			for (let i: number = fromBlockLevel ?? currentBlockLevel; i <= currentBlockLevel; i++) {
				const block: any = i === currentBlockLevel ? currentBlock : await tezos.rpc.getBlock({ block: i as any });
				const opEntry: any = await this.findOperation(block, opHash);

				if (opEntry) {
					return opEntry;
				}
			}
		} catch (err) {
			if (process.env.NODE_ENV === "development") {
				console.error(err);
			}
		}

		if (signal?.aborted) {
			throw new Error("Cancelled");
		}

		const timeToWait: number = Math.max(startedAt + syncInterval - Date.now(), 0);

		await new Promise((r) => setTimeout(r, timeToWait));

		return this.confirmOperation(opHash, {
			initializedAt,
			fromBlockLevel: currentBlockLevel ? currentBlockLevel + 1 : fromBlockLevel,
			signal,
		});
	}

	private async findOperation(block: BlockResponse, opHash: string): Promise<OperationEntry | null> {
		for (let i: number = 3; i >= 0; i--) {
			for (const op of block.operations[i]!) {
				if (op.hash === opHash) {
					return op;
				}
			}
		}
		return null;
	}
}
