import classNames from "classnames";
import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";
import { EMintModalType } from "..";
import Loader from "Components/Elements/Loader";

// Configs
import Config from "Configs/Config";

// Entities
import { IAsset } from "Entities/CollectionAsset/collectionAsset";

// Assets
import { ReactComponent as TezosIcon } from "assets/images/icons/tezos.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	onMintButtonClick: () => void;
	assetsToMint: IAsset[];
	mintModalType: EMintModalType;
	onCancelClick: () => void;
	isLoading: boolean;
};
type IState = {};

export default class ButtonContainer extends Component<IProps, IState> {
	override render() {
		return (
			<div className={classes["root"]}>
				<button className={classNames(classes["button"], classes["secondary"])} onClick={this.props.onCancelClick}>
					<I18n map="mint_modal.cancel" />
				</button>

				<div className={classes["button-container"]}>
					{this.isTotalSupplyReached() ? (
						<a
							href={Config.getInstance().get().support.objktCollectionUrl}
							className={classNames(classes["button"], classes["link"])}
							target="_blank"
							rel="noreferrer">
							<I18n map="general_text.buy_on_secondary_market" />
						</a>
					) : (
						<button className={classes["button"]} onClick={this.props.onMintButtonClick} disabled={this.props.isLoading}>
							{this.props.isLoading ? (
								<>
									<Loader className={classes["loader"]} />
									<span>
										<I18n map="general_text.purchase_in_progress" />
									</span>
								</>
							) : (
								<>
									<span>
										<I18n map="mint_modal.mint" />
									</span>

									<span className={classes["price"]}>
										{this.getMintPrice()}
										&nbsp;
										<TezosIcon className={classes["tezos-icon"]} />
									</span>
								</>
							)}
						</button>
					)}
				</div>
			</div>
		);
	}

	private getMintPrice() {
		switch (this.props.mintModalType) {
			case EMintModalType.CLAIM_REWARDS:
				return;

			case EMintModalType.YEARLY_SET:
				return Config.getInstance().get().setsPrices.yearlySet;

			case EMintModalType.SEMESTER_SET_1:
			case EMintModalType.SEMESTER_SET_2:
				return Config.getInstance().get().setsPrices.semesterSet;

			case EMintModalType.SEASON_SET_1:
			case EMintModalType.SEASON_SET_2:
			case EMintModalType.SEASON_SET_3:
			case EMintModalType.SEASON_SET_4:
				return Config.getInstance().get().setsPrices.seasonSet;
		}
	}

	private getAssetWithLowestAmountAvailable() {
		return this.props.assetsToMint.reduce((prev, current) => (prev.amountAvailable < current.amountAvailable ? prev : current));
	}

	private isTotalSupplyReached() {
		const asset = this.getAssetWithLowestAmountAvailable();
		return asset?.amountAvailable === 0;
	}
}
