import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modal";
import IsEligibleForFreeNftContent from "./IsEligibleForFreeNftContent";

// Stores
import PopupStore from "Stores/PopupStore";

// Styles
import classes from "./classes.module.scss";
import Wallet from "Stores/Wallet";

type IProps = {};
type IState = {
	isOpen: boolean | null;
	isEligibleOnlyForFreeNft: boolean;
};

export default class IsEligibleForRewardsPopup extends Component<IProps, IState> {
	private removeOnPopupStoreChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: PopupStore.getInstance().isEligibleForRewards,
			isEligibleOnlyForFreeNft: this.isEligibleOnlyForFreeNft(),
		};

		this.togglePopup = this.togglePopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	override render() {
		return (
			<Modal isOpen={this.state.isOpen || false} onClose={this.closePopup}>
				{this.state.isEligibleOnlyForFreeNft ? (
					<IsEligibleForFreeNftContent
						walletAddress={Wallet.getInstance().getWalletData().userAddress || ""}
						closePopup={this.closePopup}
					/>
				) : (
					<>
						<p className={classes["emoji"]}>
							<I18n map="is_eligible_for_rewards_modal.emoji" />
						</p>

						<p className={classes["title"]}>
							<I18n map="is_eligible_for_rewards_modal.title" />
						</p>

						<p className={classes["content"]}>
							<I18n map="is_eligible_for_rewards_modal.content.part_1" />
							<span className={classes["block"]}>
								<I18n map="is_eligible_for_rewards_modal.content.part_2" />
							</span>
						</p>

						<div className={classes["button-container"]}>
							<button className={classes["button"]} onClick={this.onClick}>
								<I18n map="is_eligible_for_rewards_modal.button" />
							</button>
						</div>
					</>
				)}
			</Modal>
		);
	}

	override componentDidMount(): void {
		this.removeOnPopupStoreChange = PopupStore.getInstance().onChange(this.togglePopup);
	}

	override componentWillUnmount(): void {
		this.removeOnPopupStoreChange();
	}

	private isEligibleOnlyForFreeNft() {
		return PopupStore.getInstance().isEligibleForFreeNft;
	}

	private togglePopup(value: boolean) {
		if (this.isEligibleOnlyForFreeNft()) {
			this.setState({ isEligibleOnlyForFreeNft: true });
		}
		this.setState({ isOpen: value });
	}

	private closePopup() {
		// We don't want to show this popup again
		// until the user refreshes the page
		// or logs out and logs back in
		// and false will trigger the not eligible popup
		PopupStore.getInstance().isEligibleForRewards = null;
	}

	private onClick() {
		// open a new tab to https://forms.gle/SBWwHsCMiKf4yLEk6
		// and close the popup
		window.open("https://forms.gle/SBWwHsCMiKf4yLEk6", "_blank");
		this.closePopup();
	}
}
