import { Component } from "react";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import NftsMosaique from "Components/Materials/MintModal/NftsMosaique";
import { EMintModalType } from "Components/Materials/MintModal";

// Stores
import Wallet, { EWalletType } from "Stores/Wallet";

// Assets
import DropEndedImage from "assets/images/drop-ended.png";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	isSmallView: boolean;
};

export default class ClaimRewards extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isSmallView: this.isSmallView(),
		};

		this.onClick = this.onClick.bind(this);
		this.onResize = this.onResize.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<img className={classes["drop-ended"]} src={DropEndedImage} alt="" />

				{this.state.isSmallView && (
					<div className={classes["mosaique-container"]}>
						<NftsMosaique type={EMintModalType.CLAIM_REWARDS} />
					</div>
				)}

				<p className={classes["title"]}>
					<I18n map="pages.home.header.claim_rewards.title" />
				</p>

				<div className={classes["content"]}>
					<div className={classes["left-part"]}>
						<p className={classNames(classes["text"], classes["bold"])}>
							<I18n map="pages.home.header.claim_rewards.did_you_buy" />
							&nbsp;
							<I18n map="pages.home.header.claim_rewards.calandar" />
						</p>

						<p className={classes["text"]}>
							<I18n map="pages.home.header.claim_rewards.connect_and_claim" />
						</p>
					</div>

					<div className={classes["right-part"]}>
						{!this.state.isSmallView && <NftsMosaique type={EMintModalType.CLAIM_REWARDS} />}
					</div>
				</div>

				<div className={classes["button-container"]}>
					<button className={classes["button"]} onClick={this.onClick}>
						<I18n map="pages.home.header.claim_rewards.check_eligibility" />
					</button>
				</div>
			</div>
		);
	}

	override componentDidMount(): void {
		window.addEventListener("resize", this.onResize);
	}

	override componentWillUnmount(): void {
		window.removeEventListener("resize", this.onResize);
	}

	private onResize() {
		this.setState({ isSmallView: this.isSmallView() });
	}

	private onClick() {
		this.connectBeaconWallet();
	}

	private async connectBeaconWallet(): Promise<void> {
		Wallet.getInstance(EWalletType.BEACON).connect();
	}

	private isSmallView() {
		return window.innerWidth < 768;
	}
}
