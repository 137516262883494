import { Component } from "react";

// Components
import { EMintModalType } from "..";

// Entities
import { calandarNfts, lightNfts } from "Entities/CalandarNft";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	type: EMintModalType;
};
type IState = {};

export default class NftsMosaique extends Component<IProps, IState> {
	override render() {
		return this.getNfts();
	}

	private getNfts() {
		switch (this.props.type) {
			case EMintModalType.CLAIM_REWARDS:
				return this.getClaimRewardsImages();

			case EMintModalType.YEARLY_SET:
				return this.getYearlyImages();

			case EMintModalType.SEMESTER_SET_1:
			case EMintModalType.SEMESTER_SET_2:
				return this.getSemesterImages();

			case EMintModalType.SEASON_SET_1:
			case EMintModalType.SEASON_SET_2:
			case EMintModalType.SEASON_SET_3:
			case EMintModalType.SEASON_SET_4:
				return this.getSeasonImages();
		}
	}

	private getClaimRewardsImages() {
		const nfts = lightNfts.slice(0, 9);

		return (
			<div className={classes["claim-rewards-images-container"]}>
				{nfts.map((nft) => (
					<img src={nft} className={classes["image"]} alt="" key={nft} />
				))}
			</div>
		);
	}

	private getYearlyImages() {
		return (
			<div className={classes["yearly-images-container"]}>
				{calandarNfts.map((nft) => (
					<img src={nft.image} className={classes["image"]} alt="" key={nft.id} />
				))}
			</div>
		);
	}

	private getSemesterImages() {
		const nfts =
			this.props.type === EMintModalType.SEMESTER_SET_1 ? calandarNfts.slice(0, 6) : calandarNfts.slice(6, calandarNfts.length);

		return (
			<div className={classes["semester-images-container"]}>
				{nfts.map((nft) => (
					<img src={nft.image} className={classes["image"]} alt="" key={nft.id} />
				))}
			</div>
		);
	}

	private getSeasonImages() {
		const nfts = this.getSeasonNfts();
		if (!nfts) return null;

		return (
			<div className={classes["season-images-container"]}>
				{nfts.map((nft) => (
					<img src={nft.image} className={classes["image"]} alt="" key={nft.id} />
				))}
			</div>
		);
	}

	private getSeasonNfts() {
		switch (this.props.type) {
			case EMintModalType.SEASON_SET_1:
				return calandarNfts.slice(0, 3);
			case EMintModalType.SEASON_SET_2:
				return calandarNfts.slice(3, 6);
			case EMintModalType.SEASON_SET_3:
				return calandarNfts.slice(6, 9);
			case EMintModalType.SEASON_SET_4:
				return calandarNfts.slice(9, 12);
			default:
				return null;
		}
	}
}
