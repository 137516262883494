import classNames from "classnames";
import React, { ReactNode } from "react";

// Styles
import classes from "./classes.module.scss";

export enum EButtonIconPosition {
	START = "start",
	END = "end",
}

export enum EButtonVariant {
	PRIMARY = "primary",
	SECONDARY = "secondary",
}

export enum EButtonSize {
	XTRASMALL = "xs",
	SMALL = "s",
	MEDIUM = "m",
	LARGE = "l",
}

type IProps = {
	onClick?: () => void;
	variant?: EButtonVariant;
	sizing?: EButtonSize;
	disabled?: boolean;
	children?: ReactNode;
	icon?: ReactNode;
	iconPosition?: EButtonIconPosition;
	className?: string;
};
type IState = {};

export default class Button extends React.Component<IProps, IState> {
	static defaultProps: IProps = {
		variant: EButtonVariant.PRIMARY,
		disabled: false,
		sizing: EButtonSize.MEDIUM,
	};

	public override render(): JSX.Element {
		const attributes = { ...this.props };
		delete attributes.iconPosition;

		return (
			<button {...attributes} className={classNames(classes["root"], this.props.className)}>
				{this.props.iconPosition === EButtonIconPosition.START && this.props.icon}
				{this.props.children}
				{this.props.iconPosition === EButtonIconPosition.END && this.props.icon}
			</button>
		);
	}
}
