import { Component } from "react";

// Components
import Modal from "Components/Elements/Modal";
import I18n from "Components/Elements/I18n";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	isOpen: boolean;
	onCloseModal: () => void;
	redirectUrl: string;
};
type IState = {};

export default class ThanksForPurchaseModal extends Component<IProps, IState> {
	override render() {
		return (
			<Modal isOpen={this.props.isOpen} onClose={this.props.onCloseModal}>
				<div className={classes["root"]}>
					<span className={classes["emoji"]}>❤️‍🔥</span>
					<p className={classes["title"]}>
						<I18n map="thanks_for_purchase_modal.title" />
					</p>
					<p className={classes["description"]}>
						<I18n map="thanks_for_purchase_modal.text" />
					</p>
					<a href={this.props.redirectUrl} className={classes["button"]} target="_blank" rel="noreferrer">
						<I18n map="thanks_for_purchase_modal.button" />
					</a>
				</div>
			</Modal>
		);
	}
}
