import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modal";

// Stores
import PopupStore from "Stores/PopupStore";

// Assets
import DropEndedImage from "assets/images/drop-ended.png";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	isOpen: boolean | null;
};

export default class IsNotEligibleForRewardsPopup extends Component<IProps, IState> {
	private removeOnPopupStoreChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: PopupStore.getInstance().isEligibleForRewards,
		};

		this.togglePopup = this.togglePopup.bind(this);
		this.closePopup = this.closePopup.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	override render() {
		return (
			<Modal isOpen={this.state.isOpen || false} onClose={this.closePopup}>
				<img className={classes["drop-ended"]} src={DropEndedImage} alt="" />

				<p className={classes["emoji"]}>
					<I18n map="is_not_eligible_for_rewards_modal.emoji" />
				</p>

				<p className={classes["title"]}>
					<I18n map="is_not_eligible_for_rewards_modal.title" />
				</p>

				<p className={classes["subtitle"]}>
					<I18n map="is_not_eligible_for_rewards_modal.not_eligible" />
				</p>

				<p className={classes["content"]}>
					<I18n map="is_not_eligible_for_rewards_modal.no_longer" />
				</p>

				<p className={classes["footer"]}>
					<I18n map="is_not_eligible_for_rewards_modal.seems_like_mistake" />
					<span className={classes["block"]}>
						<I18n map="is_not_eligible_for_rewards_modal.email_address" />
					</span>
				</p>

				<div className={classes["button-container"]}>
					<button className={classes["button"]} onClick={this.onClick}>
						<I18n map="is_not_eligible_for_rewards_modal.button" />
					</button>
				</div>
			</Modal>
		);
	}

	override componentDidMount(): void {
		this.removeOnPopupStoreChange = PopupStore.getInstance().onChange(this.togglePopup);
	}

	override componentWillUnmount(): void {
		this.removeOnPopupStoreChange();
	}

	private togglePopup(value: boolean) {
		// If the value is false in the store
		// that means that the user is not eligible for rewards
		// so we set the state to true to open the popup
		// that's why we use the !value
		this.setState({ isOpen: value !== null ? !value : false });
	}

	private closePopup() {
		// We set the value to null to close the popup
		// because if we set to false, the popup will open again
		PopupStore.getInstance().isEligibleForRewards = null;
	}

	private onClick() {
		this.closePopup();
	}
}
