import { Component, createRef } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import LazyImage from "Components/Elements/LazyImage";
import Module from "Components/Elements/Module";

// Assets
import { ReactComponent as TezosIcon } from "assets/images/icons/tezos.svg";

// Stores
import WindowStore from "Stores/WindowStore";

// Configs
import Config from "Configs/Config";

// Entities
import { ICalandarNft } from "Entities/CalandarNft";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	nft: ICalandarNft;
	asset: IAsset | undefined;
};
type IState = {
	previousScrollY: number;
};

export default class Nft extends Component<IProps, IState> {
	private onRemoveScrollListener = () => {};
	private emojiRef = createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			previousScrollY: 0,
		};

		this.onScroll = this.onScroll.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				{this.isNftCommingSoon() ? (
					<div className={classes["image-container"]}>
						<LazyImage src={this.props.nft.blurredImage} className={classes["image"]} />
					</div>
				) : (
					<>
						<NavLink
							to={Module.config.pages.Gallery.props.path.replace(":nftId", this.props.nft.id)}
							className={classes["image-container"]}>
							<LazyImage src={this.props.nft.image} className={classes["image"]} />
						</NavLink>

						<div className={classNames(classes["floating-emoji"], classes[this.props.nft.month])} ref={this.emojiRef}>
							{this.props.nft.emoji}
						</div>
					</>
				)}

				<div
					className={classNames(classes["infos"], {
						[classes["blur"]!]: this.isNftCommingSoon(),
					})}>
					<p className={classes["name"]}>
						<span className={classes["emoji"]}>{this.props.nft.emoji}</span> <I18n map={`nfts.${this.props.nft.month}.month`} />
						&nbsp;
						<span className={classes["model-name"]}>
							<I18n map="general_text.with" />
							&nbsp;
							{this.getModelNames()}
						</span>
					</p>

					<p className={classes["price"]}>
						{Config.getInstance().get().singleNftPrice} <TezosIcon />
					</p>
				</div>
			</div>
		);
	}

	override componentDidMount() {
		this.onRemoveScrollListener = WindowStore.getInstance().onScrollYDirectionChange(this.onScroll);
		if (!this.emojiRef.current) return;
		this.emojiRef.current.style.top = `${Math.random() * 100}px`;
		Math.random() * 2 > 1 ? (this.emojiRef.current.style.right = "0") : (this.emojiRef.current.style.left = "0");
	}

	override componentWillUnmount() {
		this.onRemoveScrollListener();
	}

	private isNftCommingSoon() {
		const comingSoonDate = this.props.nft.dateOfCommingSoon;
		const date = new Date(comingSoonDate);
		const now = new Date();

		return date.getTime() > now.getTime();
	}

	private getModelNames() {
		const modelNames: string[] = [];

		this.props.nft.modelsName.forEach((name) => {
			modelNames.push(name);
		});

		return modelNames.join(` ${I18n.translate("general_text.and")} `);
	}

	private onScroll() {
		if (!this.emojiRef.current) return;

		const actualPosition = +window.getComputedStyle(this.emojiRef.current).top.replace("px", "");
		const offset = this.isPreviousScrollYBiggerThanCurrentScrollY() ? -0.3 : 0.3;
		const newPosition = actualPosition + offset;

		this.emojiRef.current.style.top = `${newPosition}px`;
		this.setPreviousScrollY();
	}

	private setPreviousScrollY() {
		this.setState({
			previousScrollY: window.scrollY,
		});
	}

	private isPreviousScrollYBiggerThanCurrentScrollY() {
		return this.state.previousScrollY > window.scrollY;
	}
}
