import { Component } from "react";

// Components
import I18n from "Components/Elements/I18n";

// Assets
import AliceMoitieImage from "assets/images/alicemoitie.jpeg";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {};

export default class AliceMoitiePresentation extends Component<IProps, IState> {
	override render() {
		return (
			<section className={classes["root"]}>
				<div className={classes["image-container"]}>
					<span className={classes["emoji"]}>📸</span>
					<img src={AliceMoitieImage} alt="Alice moitié" className={classes["image"]} />
				</div>

				<div className={classes["text-container"]}>
					<p className={classes["title"]}>
						<I18n map="alice_moitie_presentation.title" />
					</p>
					<p className={classes["description"]}>
						<I18n map="alice_moitie_presentation.description" />
					</p>
				</div>
			</section>
		);
	}
}
