import { Component } from "react";
import classNames from "classnames";

// Components
import I18n from "Components/Elements/I18n";
import I18nStore from "Components/Elements/I18n/I18nStore";
import { EMintModalType } from "Components/Materials/MintModal";

// Assets
import { ReactComponent as TezosIcon } from "assets/images/icons/tezos.svg";
import PackImage1 from "assets/images/image35.png";
import PackImage2 from "assets/images/image38.png";
import PackImage3 from "assets/images/image47.png";

// Configs
import Config from "Configs/Config";

// Styles
import classes from "./classes.module.scss";

type Props = {
	isAvailable: boolean;
	onMintButtonClick: (mintModalType: EMintModalType) => void;
};
type IState = {};

export default class BuyYearlySet extends Component<Props, IState> {
	constructor(props: Props) {
		super(props);
		this.onMintButtonClick = this.onMintButtonClick.bind(this);
	}

	override render() {
		const lng = I18nStore.getInstance().lang;

		return (
			<div className={classes["root"]} onClick={this.onMintButtonClick}>
				<div className={classes["set"]}>
					<div className={classes["images"]}>
						<img
							src={PackImage1}
							alt=""
							className={classNames(classes["image"], {
								[classes["blur"]!]: !this.props.isAvailable,
							})}
						/>
						<img
							src={PackImage2}
							alt=""
							className={classNames(classes["image"], {
								[classes["blur"]!]: !this.props.isAvailable,
							})}
						/>
						<img
							src={PackImage3}
							alt=""
							className={classNames(classes["image"], {
								[classes["blur"]!]: !this.props.isAvailable,
							})}
						/>
					</div>

					<p className={classNames(classes["text"], classes[lng])}>
						<I18n map="buy_sets.yearly_set.mint" />
						&nbsp;
						<span className={classes["bold"]}>
							<I18n map={`treats_and_rewards.yearly_set.title`} />
						</span>
						<br />
						<I18n map="buy_sets.yearly_set.pictures" />
						&nbsp;
						<span className={classes["bold"]}>
							<I18n map="buy_sets.yearly_set.rewards" />
						</span>
					</p>
				</div>

				<button
					className={classNames(classes["button"], {
						[classes["center"]!]: !this.props.isAvailable,
					})}>
					{!this.props.isAvailable ? (
						<I18n map="buy_sets.available_soon" />
					) : (
						<>
							<span>
								<I18n map="buy_sets.button" />
							</span>
							&nbsp;
							<span>
								{Config.getInstance().get().setsPrices.yearlySet} <TezosIcon className={classes["tezos-icon"]} />
							</span>
						</>
					)}
				</button>
			</div>
		);
	}

	private onMintButtonClick() {
		if (!this.props.isAvailable) return;
		this.props.onMintButtonClick(EMintModalType.YEARLY_SET);
	}
}
