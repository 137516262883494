import { Component } from "react";
import { NavLink } from "react-router-dom";

// Components
import Button, { EButtonVariant } from "Components/Elements/Button";
import SwitchLanguage from "./SwitchLanguage";
import Module from "Components/Elements/Module";
import I18n from "Components/Elements/I18n";

// Services
import { IWalletData } from "Services/Wallet/IWalletInterface";

// Configs
import Config from "Configs/Config";

// Stores
import Wallet, { EWalletType } from "Stores/Wallet";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	useraddress: string | null;
};

export default class Header extends Component<IProps, IState> {
	private removeOnWalletChange = () => {};

	constructor(props: IProps) {
		super(props);

		this.state = {
			useraddress: Wallet.getInstance().getWalletData().userAddress,
		};

		this.connectOrDisconnectWallet = this.connectOrDisconnectWallet.bind(this);
	}

	override render() {
		return (
			<header className={classes["root"]}>
				<div className={classes["content"]}>
					<NavLink to={Module.config.pages.Home.props.path} className={classes["title"]}>
						Alice Moitié <span className={classes["slash"]}>/</span> têtu·
					</NavLink>
					<div className={classes["divider"]} />
					{this.isPageComingSoon() ? (
						<Button variant={EButtonVariant.SECONDARY}>Coming soon</Button>
					) : (
						<Button variant={EButtonVariant.SECONDARY} onClick={this.connectOrDisconnectWallet}>
							{this.state.useraddress ? <I18n map="header.log_out" /> : <I18n map="header.connect_wallet" />}
						</Button>
					)}
					<SwitchLanguage />
				</div>
			</header>
		);
	}

	override componentDidMount() {
		this.removeOnWalletChange = Wallet.getInstance().onChange((web3Event: IWalletData | null) => this.onWalletChange(web3Event));
	}

	override componentWillUnmount() {
		this.removeOnWalletChange();
	}

	private onWalletChange(walletData: IWalletData | null) {
		this.setState({
			useraddress: walletData?.userAddress ?? null,
		});
	}

	private async connectOrDisconnectWallet() {
		this.state.useraddress ? await this.disconnectWallet() : await this.connectBeaconWallet();
	}

	private async connectBeaconWallet(): Promise<void> {
		Wallet.getInstance(EWalletType.BEACON).connect();
	}

	private async disconnectWallet() {
		Wallet.getInstance().disconnect();
	}

	private isPageComingSoon() {
		const date = new Date(Config.getInstance().get().comingSoon.end);
		const now = new Date();

		return date.getTime() > now.getTime();
	}
}
