import { createRef, RefObject } from "react";

// Components
import I18n from "Components/Elements/I18n";
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplate/DefaultTemplate";
import CalandarNft from "./CalandarNft";
import TreatsAndRewards from "./TreatsAndRewards";
import CommingSoonNewsletter from "./CommingSoonNewsletter";
import AliceMoitiePresentation from "./AliceMoitiePresentation";
import FAQ from "./FAQ";
import TetuDescriptionHeader from "./TetuDescriptionHeader";
import Social from "./Social";
import EndingDate from "Components/Elements/EndingDate";

// Configs
import Config from "Configs/Config";

// Assets
import { ReactComponent as ArrowDown } from "assets/images/icons/arrow-down.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	isMobileView: boolean;
};

export default class Home extends BasePage<IProps, IState> {
	private readonly _calandarNftRef: RefObject<HTMLDivElement> = createRef();

	constructor(props: IProps) {
		super(props);

		this.state = {
			isMobileView: false,
		};

		this.onResize = this.onResize.bind(this);
		this.scrollToRewards = this.scrollToRewards.bind(this);
	}

	public override render() {
		return (
			<DefaultTemplate title={I18n.translate("pages.home.page_title")}>
				<div className={classes["root"]}>
					<h1 className={classes["title"]}>
						<I18n map="pages.home.title_part_one" />
						<br />
						<I18n map="pages.home.title_part_two" />
					</h1>

					{this.isPageComingSoon() ? (
						<p className={classes["coming-soon-description"]}>
							<I18n map="pages.home.coming_soon_description" />
						</p>
					) : (
						<TetuDescriptionHeader />
					)}

					{!this.isPageComingSoon() && this.state.isMobileView && (
						<div className={classes["scroll-to-rewards"]} onClick={this.scrollToRewards}>
							<p className={classes["text"]}>
								<I18n map="treats_and_rewards.title" />
							</p>
							<ArrowDown className={classes["arrow-down"]} />
						</div>
					)}

					{this.isPageComingSoon() ? (
						<CommingSoonNewsletter>
							<CalandarNft />
						</CommingSoonNewsletter>
					) : (
						<>
							<h2 className={classes["subtitle"]}>
								<I18n map="pages.home.subtitle" />
							</h2>

							<CalandarNft />

							<div className={classes["subtitle-container"]}>
								<h2 className={classes["subtitle"]}>
									<I18n map="treats_and_rewards.title" />
								</h2>
								<EndingDate className={classes["ending-date"]} />
							</div>

							<TreatsAndRewards customRef={this._calandarNftRef} />
							<AliceMoitiePresentation />
							<FAQ />
							<Social />
						</>
					)}
				</div>
			</DefaultTemplate>
		);
	}

	override componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.onResize();
	}

	override componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	private onResize() {
		if (!this.state.isMobileView && window.innerWidth <= 424) {
			this.setState({ isMobileView: true });
		}

		if (this.state.isMobileView && window.innerWidth > 424) {
			this.setState({ isMobileView: false });
		}
	}

	private scrollToRewards() {
		if (!this._calandarNftRef.current) return;
		this._calandarNftRef.current.scrollIntoView({ behavior: "smooth" });
		// window.scrollBy(0, -100);
	}

	private isPageComingSoon() {
		const date = new Date(Config.getInstance().get().comingSoon.end);
		const now = new Date();

		return date.getTime() > now.getTime();
	}
}
