import { Navigate, NavigateFunction, useNavigate, useParams } from "react-router-dom";

// Components
import BasePage from "Components/Pages/Base";
import DefaultTemplate from "Components/PageTemplate/DefaultTemplate";
import Module from "Components/Elements/Module";
import GalleryHeader from "./GalleryHeader";
import GalleryFooter from "./GalleryFooter";
import GalleryBody from "./GalleryBody";
import BuySets from "./BuySet";
import { ETreatsAndRewardsPart } from "Components/Materials/MintModal";
import I18n from "Components/Elements/I18n";

// Stores
import Wallet from "Stores/Wallet";

// Configs
import Config from "Configs/Config";

// Entities
import { calandarNfts, ICalandarNft } from "Entities/CalandarNft";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";

// Api
import CollectionAsset from "Api/Factory/AppCollectionAsset/CollectionAsset";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	nftId: number;
	navigate: NavigateFunction;
};
type IClassProps = IProps & {};
type IState = {
	nftInfos: ICalandarNft | null;
	asset: IAsset | null;
	seasonSetPart: ETreatsAndRewardsPart;
	semesterSetPart: ETreatsAndRewardsPart;
	userAddress: string | null;
};

class GalleryClass extends BasePage<IClassProps, IState> {
	private removeOnWalletChange = () => {};

	constructor(props: IClassProps) {
		super(props);
		this.state = {
			nftInfos: null,
			asset: null,
			seasonSetPart: ETreatsAndRewardsPart.PART_ONE,
			semesterSetPart: ETreatsAndRewardsPart.PART_ONE,
			userAddress: null,
		};
		this.setUserAddress = this.setUserAddress.bind(this);
	}

	override render() {
		if (!this.isNftAvailable()) return <Navigate to={Module.config.pages.Home.props.path} />;
		if (!this.state.nftInfos || !this.state.asset) return null;

		return (
			<DefaultTemplate title={I18n.translate("pages.gallery.page_title")} hideHeader noPadding noMaxWidth noPoweredByMinteed>
				<div className={classes["root"]}>
					<section className={classes["gallery-section"]}>
						<GalleryHeader navigate={this.props.navigate} nft={this.state.nftInfos} />
						<GalleryBody navigate={this.props.navigate} nftInfos={this.state.nftInfos} asset={this.state.asset} />
						<GalleryFooter nftInfos={this.state.nftInfos} asset={this.state.asset} userAddress={this.state.userAddress} />
					</section>
					<section className={classes["buy-set-section"]}>
						<BuySets
							seasonSetPart={this.state.seasonSetPart}
							semesterSetPart={this.state.semesterSetPart}
							isSeasonSetAvailable={this.isSeasonSetAvailable()}
							isSemesterSetAvailable={this.isSemesterSetAvailable()}
							isYearlySetAvailable={this.isYearlySetAvailable()}
						/>
					</section>
				</div>
			</DefaultTemplate>
		);
	}

	override async componentDidMount() {
		this.removeOnWalletChange = Wallet.getInstance().onChange(this.setUserAddress);
		const nft = this.getNft(this.props.nftId);
		if (!nft) return;
		this.setAsset(nft);
		this.setSeasonAndSemesterSetPart(nft);
		this.setUserAddress();
	}

	override async componentDidUpdate(prevProps: IClassProps) {
		if (this.state.userAddress !== Wallet.getInstance().getWalletData().userAddress) this.setUserAddress();

		if (prevProps.nftId === this.props.nftId || !this.props.nftId) return;

		const nft = this.getNft(this.props.nftId);
		if (!nft) return;
		this.setAsset(nft);
		this.setSeasonAndSemesterSetPart(nft);
	}

	override componentWillUnmount() {
		this.removeOnWalletChange();
	}

	private setUserAddress() {
		this.setState({ userAddress: Wallet.getInstance().getWalletData().userAddress });
	}

	private getNft(nftId: number) {
		return calandarNfts.find((nft) => +nft.id === nftId);
	}

	private async setAsset(nft: ICalandarNft) {
		try {
			const asset = await CollectionAsset.getInstance().get({ collectionAssetId: +nft.id });
			this.setState({ nftInfos: nft, asset });
		} catch (error) {
			console.error(error);
		}
	}

	private isNftAvailable() {
		const nft = this.getNft(this.props.nftId);
		if (!nft) return false;

		const now = new Date();
		const nftDate = new Date(nft.dateOfCommingSoon);
		return now.getTime() > nftDate.getTime();
	}

	private isSeasonSetAvailable() {
		const now = new Date();
		const seasonSetDate = new Date(this.getSeasonComingSoonDate(this.state.seasonSetPart));
		if (!seasonSetDate) return false;
		return now.getTime() > seasonSetDate.getTime();
	}

	private isSemesterSetAvailable() {
		const now = new Date();
		const semesterSetDate = new Date(this.getSemesterComingSoonDate(this.state.semesterSetPart) ?? "");
		if (!semesterSetDate) return false;
		return now.getTime() > semesterSetDate.getTime();
	}

	private isYearlySetAvailable() {
		const now = new Date();
		const yearlySetDate = new Date(Config.getInstance().get().setsComingSoon.yearlySet.end);
		if (!yearlySetDate) return false;
		return now.getTime() > yearlySetDate.getTime();
	}

	private getSeasonComingSoonDate(treatAndRewardsType: ETreatsAndRewardsPart) {
		switch (treatAndRewardsType) {
			case ETreatsAndRewardsPart.PART_ONE:
				return Config.getInstance().get().setsComingSoon.seasonSet.partOne.end;
			case ETreatsAndRewardsPart.PART_TWO:
				return Config.getInstance().get().setsComingSoon.seasonSet.partTwo.end;
			case ETreatsAndRewardsPart.PART_THREE:
				return Config.getInstance().get().setsComingSoon.seasonSet.partThree.end;
			case ETreatsAndRewardsPart.PART_FOUR:
				return Config.getInstance().get().setsComingSoon.seasonSet.partFour.end;
		}
	}

	private getSemesterComingSoonDate(treatAndRewardsType: ETreatsAndRewardsPart) {
		switch (treatAndRewardsType) {
			case ETreatsAndRewardsPart.PART_ONE:
				return Config.getInstance().get().setsComingSoon.semesterSet.partOne.end;
			case ETreatsAndRewardsPart.PART_TWO:
				return Config.getInstance().get().setsComingSoon.semesterSet.partTwo.end;
			default:
				return null;
		}
	}

	private setSeasonAndSemesterSetPart(nft: ICalandarNft) {
		this.setState({
			seasonSetPart: nft.seasonTreatsAndRewardsType,
			semesterSetPart: nft.semesterTreatsAndRewardsType,
		});
	}
}

export default function Gallery() {
	const { nftId } = useParams<{ nftId: string }>();
	const navigate = useNavigate();

	return <GalleryClass nftId={+nftId!} navigate={navigate} />;
}
