import { Component, ReactNode } from "react";
import classNames from "classnames";

// Components
import Modal from "Components/Elements/Modal";
import I18n from "Components/Elements/I18n";
import ButtonContainer from "./ButtonContainer";
import NftsMosaique from "./NftsMosaique";
import ThanksForPurchaseModal from "../ThanksForPurchaseModal";

// Api
import Collection from "Api/Factory/AppCollection/Collection";

// Stores
import UserStore from "Stores/UserStore";
import Wallet from "Stores/Wallet";
import TzContract from "Stores/Contract/TzContract";
import Toasts from "Stores/Toasts";

// Assets
import { ReactComponent as SuccessIcon } from "assets/images/icons/check.svg";

// Configs
import Config from "Configs/Config";

// Entities
import { IAsset } from "Entities/CollectionAsset/collectionAsset";
import { IUser } from "Entities/AuthFactory/user";
import { IAppAssetOwner } from "Entities/CollectionAsset/appAssetOwner";

// Styles
import classes from "./classes.module.scss";

export enum EMintModalType {
	CLAIM_REWARDS = "claim-rewards",
	YEARLY_SET = "yearly-set",
	SEMESTER_SET_1 = "semester-set-1",
	SEMESTER_SET_2 = "semester-set-2",
	SEASON_SET_1 = "season-set-1",
	SEASON_SET_2 = "season-set-2",
	SEASON_SET_3 = "season-set-3",
	SEASON_SET_4 = "season-set-4",
}

export enum ETreatsAndRewardsPart {
	PART_ONE = "part_one",
	PART_TWO = "part_two",
	PART_THREE = "part_three",
	PART_FOUR = "part_four",
}

type IProps = {
	isOpen: boolean;
	onCloseModal: () => void;
	type: EMintModalType;
	isSetAvailable: boolean;
};
type IState = {
	title: ReactNode;
	descriptionPartOne: ReactNode;
	descriptionPartTwo: ReactNode;
	descriptionPartThree: ReactNode | null;
	descriptionPartFour: ReactNode | null;
	assetsToMint: IAsset[] | null;
	user: IUser | null;
	isThanksForPurchaseModalOpen: boolean;
	isLoading: boolean;
};

export default class MintModal extends Component<IProps, IState> {
	private readonly _quantityToMint = 1;

	constructor(props: IProps) {
		super(props);

		this.state = {
			title: "",
			descriptionPartOne: "",
			descriptionPartTwo: "",
			descriptionPartThree: null,
			descriptionPartFour: null,
			assetsToMint: null,
			user: UserStore.getInstance().user,
			isThanksForPurchaseModalOpen: false,
			isLoading: false,
		};

		this.onMintButtonClick = this.onMintButtonClick.bind(this);
		this.closeThanksForPurchaseModal = this.closeThanksForPurchaseModal.bind(this);
	}

	override render() {
		if (!this.state.assetsToMint || this.state.assetsToMint.length === 0) return;

		const redirectUrl = `${Config.getInstance().get().support.minteedUrl}mint/collection/${Config.getInstance().get().collectionId}`;

		return (
			<Modal isOpen={this.props.isOpen} onClose={this.props.onCloseModal}>
				<div className={classes["root"]}>
					<p className={classes["title"]}>
						<I18n map="mint_modal.title" />
					</p>

					<div
						className={classNames(classes["mint-type-container"], {
							[classes["yearly-set-background"]!]: this.isYearlySetBackground(),
							[classes["semester-set-background"]!]: this.isSemesterSetBackground(),
							[classes["season-set-background"]!]: this.isSeasonSetBackground(),
						})}>
						<div className={classes["mint-type-infos"]}>
							<p className={classes["mint-type-title"]}>{this.state.title}</p>
							<p className={classes["mint-type-description"]}>{this.state.descriptionPartOne}</p>
							<p className={classes["mint-type-description"]}>{this.state.descriptionPartTwo}</p>
							<p className={classes["mint-type-description"]}>{this.state.descriptionPartThree}</p>
							<p className={classes["mint-type-description"]}>{this.state.descriptionPartFour}</p>
						</div>

						<NftsMosaique type={this.props.type} />
					</div>

					<ButtonContainer
						onMintButtonClick={this.onMintButtonClick}
						assetsToMint={this.state.assetsToMint}
						mintModalType={this.props.type}
						onCancelClick={this.props.onCloseModal}
						isLoading={this.state.isLoading}
					/>
				</div>
				<ThanksForPurchaseModal
					redirectUrl={redirectUrl}
					isOpen={this.state.isThanksForPurchaseModalOpen}
					onCloseModal={this.closeThanksForPurchaseModal}
				/>
			</Modal>
		);
	}

	override componentDidMount() {
		if (!this.props.isSetAvailable) return;
		this.setModalData();
	}

	override componentDidUpdate(prevProps: Readonly<IProps>) {
		if (prevProps.type === this.props.type || !this.props.isSetAvailable) return;
		this.setModalData();
	}

	private setModalData() {
		switch (this.props.type) {
			case EMintModalType.CLAIM_REWARDS:
				return;

			case EMintModalType.YEARLY_SET:
				return this.setYearlyState();

			case EMintModalType.SEMESTER_SET_1:
				return this.setSemesterState(this.props.type, ETreatsAndRewardsPart.PART_ONE);
			case EMintModalType.SEMESTER_SET_2:
				return this.setSemesterState(this.props.type, ETreatsAndRewardsPart.PART_TWO);

			case EMintModalType.SEASON_SET_1:
				return this.setSeasonState(this.props.type, ETreatsAndRewardsPart.PART_ONE);
			case EMintModalType.SEASON_SET_2:
				return this.setSeasonState(this.props.type, ETreatsAndRewardsPart.PART_TWO);
			case EMintModalType.SEASON_SET_3:
				return this.setSeasonState(this.props.type, ETreatsAndRewardsPart.PART_THREE);
			case EMintModalType.SEASON_SET_4:
				return this.setSeasonState(this.props.type, ETreatsAndRewardsPart.PART_FOUR);
		}
	}

	private async setYearlyState() {
		const collection = await this.getCollection();

		this.setState({
			title: <I18n map="treats_and_rewards.yearly_set.title" />,
			descriptionPartOne: <I18n map="treats_and_rewards.yearly_set.description_part_one" />,
			descriptionPartTwo: <I18n map="treats_and_rewards.yearly_set.description_part_two" />,
			descriptionPartThree: <I18n map="treats_and_rewards.yearly_set.description_part_three" />,
			descriptionPartFour: <I18n map="treats_and_rewards.yearly_set.description_part_four" />,
			assetsToMint: collection?.appCollectionAssets ?? null,
		});
	}

	private async setSemesterState(mintModalType: EMintModalType, part: ETreatsAndRewardsPart) {
		const assets = await this.getSemesterAssets(mintModalType);

		this.setState({
			title: <I18n map={`treats_and_rewards.semester_set.${part}.title`} />,
			descriptionPartOne: <I18n map={`treats_and_rewards.semester_set.${part}.description_part_one`} />,
			descriptionPartTwo: <I18n map={`treats_and_rewards.semester_set.${part}.description_part_two`} />,
			descriptionPartThree: <I18n map={`treats_and_rewards.semester_set.${part}.description_part_three`} />,
			descriptionPartFour: null,
			assetsToMint: assets ?? null,
		});
	}

	private async setSeasonState(mintModalType: EMintModalType, part: ETreatsAndRewardsPart) {
		const assets = await this.getSeasonAsset(mintModalType);

		this.setState({
			title: <I18n map={`treats_and_rewards.season_set.${part}.title`} />,
			descriptionPartOne: <I18n map={`treats_and_rewards.season_set.${part}.description_part_one`} />,
			descriptionPartTwo: <I18n map={`treats_and_rewards.season_set.${part}.description_part_two`} />,
			descriptionPartThree: null,
			descriptionPartFour: null,
			assetsToMint: assets ?? null,
		});
	}

	private isYearlySetBackground() {
		return this.props.type === EMintModalType.YEARLY_SET;
	}

	private isSemesterSetBackground() {
		return this.props.type === EMintModalType.SEMESTER_SET_1 || this.props.type === EMintModalType.SEMESTER_SET_2;
	}

	private isSeasonSetBackground() {
		return (
			this.props.type === EMintModalType.SEASON_SET_1 ||
			this.props.type === EMintModalType.SEASON_SET_2 ||
			this.props.type === EMintModalType.SEASON_SET_3 ||
			this.props.type === EMintModalType.SEASON_SET_4
		);
	}

	private async getCollection() {
		const collectionId = Config.getInstance().get().collectionId;
		try {
			return Collection.getInstance().get({ collectionId });
		} catch (error) {
			console.error(error);
			return;
		}
	}

	private async getSemesterAssets(mintModalType: EMintModalType) {
		const collection = await this.getCollection();
		if (!collection) return;
		return collection.appCollectionAssets.slice(
			mintModalType === EMintModalType.SEMESTER_SET_1 ? 0 : 6,
			mintModalType === EMintModalType.SEMESTER_SET_1 ? 6 : 12,
		);
	}

	private async getSeasonAsset(mintModalType: EMintModalType) {
		const collection = await this.getCollection();
		if (!collection) return;

		const winterAssets = collection.appCollectionAssets.slice(0, 3);
		const springAssets = collection.appCollectionAssets.slice(3, 6);
		const summerAssets = collection.appCollectionAssets.slice(6, 9);
		const autumnAssets = collection.appCollectionAssets.slice(9, 12);

		switch (mintModalType) {
			case EMintModalType.SEASON_SET_1:
				return winterAssets;
			case EMintModalType.SEASON_SET_2:
				return springAssets;
			case EMintModalType.SEASON_SET_3:
				return summerAssets;
			case EMintModalType.SEASON_SET_4:
				return autumnAssets;
		}

		return;
	}

	private onMintButtonClick() {
		if (!this.state.assetsToMint) return;
		const tokenIds = this.state.assetsToMint.map((asset) => asset.tokenId!);
		const mintPrice = this.state.assetsToMint[0]!.mintPrice;

		this.mintAsset(tokenIds, this._quantityToMint, mintPrice);
	}

	private async mintAsset(assetContractKeys: number[], assetQuantities: number, assetPrice: string) {
		if (!this.state.assetsToMint) return;

		const titleSuccess = (
			<div className={classes["title-toast"]}>
				<SuccessIcon />
				{I18n.translate("toasts.mint_success")}
			</div>
		);

		try {
			if (!Wallet.getInstance().getWalletData().userAddress) await Wallet.getInstance().connect();
			this.setState({ isLoading: true });

			this.state.assetsToMint.forEach((asset) => {
				if (this.getMaxMintable(asset) < assetQuantities) throw new Error("Max mintable number can not be exceeded.");
			});

			const collection = await Collection.getInstance().get({
				collectionId: Config.getInstance().get().collectionId,
			});
			const collectionContract = await TzContract.getInstance().collectionContract(collection?.contractAddress!);
			const transaction = await collectionContract.mintBatchAsset(assetContractKeys, assetQuantities, assetPrice);
			await TzContract.getInstance().confirmOperation(transaction.opHash);

			this.openThanksForPurchaseModal();
			this.setState({ isLoading: false });

			Toasts.getInstance().open({
				text: I18n.translate("toasts.mint_success"),
				title: titleSuccess,
				time: 5000,
				closable: true,
			});
		} catch (e) {
			this.setState({ isLoading: false });
			console.log(e);
			const error = e as Error;
			Toasts.getInstance().open({
				text: error.message,
				title: I18n.translate("toasts.an_error_occured"),
				time: 5000,
				closable: true,
			});
		}
	}

	private getMaxMintable(asset: IAsset): number {
		const amountMintedForCurrentUser = this.getAmountMintedForAppAssetOwner(asset);

		if (!asset || !asset.appAssetOwners) return 0;

		const amountAvailable = asset.amountAvailable;
		const maxMintPerWallet = asset.maxMintPerWallet;
		const currentMaxMintPerWallet = maxMintPerWallet - amountMintedForCurrentUser;

		return Math.min(amountAvailable, currentMaxMintPerWallet);
	}

	private getAmountMintedForAppAssetOwner(asset: IAsset): number {
		const appAssetOwner = this.getAppAssetOwner(asset);
		if (!appAssetOwner) return 0;
		return appAssetOwner.balance;
	}

	private getAppAssetOwner(asset: IAsset): IAppAssetOwner | undefined {
		return asset.appAssetOwners.find((owner) => owner.appUser.userAddress === this.state.user?.userAddress);
	}

	private closeThanksForPurchaseModal() {
		this.setState({ isThanksForPurchaseModalOpen: false });
		this.props.onCloseModal();
	}

	private openThanksForPurchaseModal() {
		this.setState({ isThanksForPurchaseModalOpen: true });
	}
}
