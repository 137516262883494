import { Component } from "react";

// Assets
import { ReactComponent as MinteedLogo } from "assets/images/logos/logo-responsiv-light.svg";
import AnimatedMinteedLogo from "assets/images/logos/logo-animation-light.gif";

// Styles
import classes from "./classes.module.scss";
import classNames from "classnames";

type IProps = {};
type IState = {};

export default class PoweredByMinteed extends Component<IProps, IState> {
	override render() {
		return (
			<a href="https://www.minteed-lab.com/" target="_blank" rel="noreferrer" className={classes["root"]}>
				<span className={classNames(classes["text"], classes["margin-right"])}>Powered by</span>
				<img src={AnimatedMinteedLogo} alt="Powered by minteed" width={30} className={classes["margin-right"]} />
				<MinteedLogo />
			</a>
		);
	}
}
