import { Component } from "react";
import { NavigateFunction } from "react-router-dom";

// Components
import Module from "Components/Elements/Module";
import LazyImage from "Components/Elements/LazyImage";

// Entities
import { calandarNfts, ICalandarNft } from "Entities/CalandarNft";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";

// Assets
import { ReactComponent as ChevronLeft } from "assets/images/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "assets/images/icons/chevron-right.svg";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	nftInfos: ICalandarNft;
	asset: IAsset;
	navigate: NavigateFunction;
};
type IState = {};

export default class GalleryBody extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.goToNextNft = this.goToNextNft.bind(this);
		this.goToPreviousNft = this.goToPreviousNft.bind(this);
	}

	override render() {
		return (
			<div className={classes["root"]}>
				<div className={classes["image-container"]}>
					<LazyImage className={classes["image"]} alt="nft" src={this.props.nftInfos.image} />
				</div>
				<div className={classes["arrow-container"]}>
					<ChevronLeft className={classes["arrow"]} onClick={this.goToPreviousNft} />
					<ChevronRight className={classes["arrow"]} onClick={this.goToNextNft} />
				</div>
			</div>
		);
	}

	private goToNextNft() {
		const nextNftId = this.getNextNftId(+this.props.asset.id);
		this.props.navigate(Module.config.pages.Gallery.props.path.replace(":nftId", nextNftId.toString()));
	}

	private goToPreviousNft() {
		const prevNftId = this.getPreviousNftId(+this.props.asset.id);
		this.props.navigate(Module.config.pages.Gallery.props.path.replace(":nftId", prevNftId.toString()));
	}

	private getNextNftId(actualNftId: number): number {
		let nextNftId = actualNftId + 1;

		if (nextNftId > +calandarNfts[calandarNfts.length - 1]!.id) {
			nextNftId = +calandarNfts[0]!.id;
		}

		const nextNft = calandarNfts.find((nft) => nft.id === nextNftId.toString());

		const today = new Date();
		const nftComingSoonDate = new Date(nextNft!.dateOfCommingSoon);

		if (nftComingSoonDate > today) {
			return this.getNextNftId(nextNftId);
		}

		return nextNftId;
	}

	private getPreviousNftId(actualNftId: number): number {
		let prevNftId = actualNftId - 1;

		if (prevNftId < +calandarNfts[0]!.id) {
			prevNftId = +calandarNfts[calandarNfts.length - 1]!.id;
		}

		const prevNft = calandarNfts.find((nft) => nft.id === prevNftId.toString());

		const today = new Date();
		const nftComingSoonDate = new Date(prevNft!.dateOfCommingSoon);

		if (nftComingSoonDate > today) {
			return this.getPreviousNftId(prevNftId);
		}

		return prevNftId;
	}
}
