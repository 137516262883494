import I18n from "Components/Elements/I18n";
import { Component } from "react";

// Styles
import classes from "./classes.module.scss";

type IProps = {
	closePopup: () => void;
	walletAddress: string;
};
type IState = {};

export default class IsEligibleForFreeNftContent extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	override render() {
		return (
			<>
				<p className={classes["emoji"]}>
					<I18n map="is_eligible_for_free_nft.emoji" />
				</p>

				<p className={classes["title"]}>
					<I18n map="is_eligible_for_free_nft.title" />
				</p>

				<p className={classes["content"]}>
					<I18n map="is_eligible_for_free_nft.content" />
				</p>

				<div className={classes["button-container"]}>
					<button className={classes["button"]} onClick={this.onClick}>
						<I18n map="is_eligible_for_free_nft.button" />
					</button>
				</div>
			</>
		);
	}

	private onClick() {
		window.open("https://tzkt.io/" + this.props.walletAddress + "/balances/nft", "_blank");
		this.props.closePopup();
	}
}
