import { Component, createRef } from "react";

// Components
import Nft from "./Nft";

// Entities
import { calandarNfts } from "Entities/CalandarNft";
import { IAsset } from "Entities/CollectionAsset/collectionAsset";

// Api
import Collection from "Api/Factory/AppCollection/Collection";

// Configs
import Config from "Configs/Config";

// Styles
import classes from "./classes.module.scss";

type IProps = {};
type IState = {
	collectionAssets: IAsset[] | null;
};

export default class CalandarNft extends Component<IProps, IState> {
	private emojiRef = createRef<HTMLDivElement>();

	constructor(props: IProps) {
		super(props);

		this.state = {
			collectionAssets: null,
		};
	}

	override render() {
		if (!this.state.collectionAssets) return;

		return (
			<div className={classes["root"]}>
				{calandarNfts.map((nft) => {
					const asset = this.state.collectionAssets?.find((asset) => +asset.id === +nft.id);
					return <Nft nft={nft} asset={asset} key={nft.id} />;
				})}
			</div>
		);
	}

	override async componentDidMount() {
		try {
			const collection = await Collection.getInstance().get({ collectionId: Config.getInstance().get().collectionId });
			if (!collection) return;
			this.setState({ collectionAssets: collection.appCollectionAssets });
		} catch (error) {
			console.error(error);
		}
	}
}
